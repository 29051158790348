import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EMERGENCY_TYPE, EMERGENCY_BOOK } from './api';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Circles } from 'react-loader-spinner';
import { Container, Row, Col, Button } from 'react-bootstrap';

function EmergencyScreen() {
    const [selectedButton, setSelectedButton] = useState('');
    const [canProceed, setCanProceed] = useState(false);
    const [emergencyTypes, setEmergencyTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noAmbulanceModal, setNoAmbulanceModal] = useState(false);
    const [bookingId, setBookingId] = useState(null);
    const [timer, setTimer] = useState(120); // Start from 120 seconds
    const [isTimerActive, setIsTimerActive] = useState(false); // To track if timer is active
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    let timerInterval = null;
    const navigate = useNavigate();

    useEffect(() => {
        fetchEmergencyTypes();
        fetchLocationFromLocalStorage();
    }, []);

    const fetchLocationFromLocalStorage = () => {
        const latitude = localStorage.getItem('latitude');
        const longitude = localStorage.getItem('longitude');
        if (latitude && longitude) {
            setLocation({ latitude: parseFloat(latitude), longitude: parseFloat(longitude) });
        } else {
            console.error('Location not found in localStorage');
        }
    };

    const fetchEmergencyTypes = async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(EMERGENCY_TYPE, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setEmergencyTypes(response.data.data.types);
        } catch (error) {
            console.error('Error fetching emergency types:', error);
        }
    };

    const handleNoAmbulanceFound = () => {
        setLoading(false);
        setNoAmbulanceModal(true);
    };

    const handleButtonClick = (slug) => {
        setSelectedButton(slug);
        setCanProceed(true);
    };
    const handleCancelBooking = () => {
        // Stop the timer and reset states
        clearInterval(timerInterval);
        setIsTimerActive(false);
        setTimer(120); // Reset timer to 120 seconds
        setCanProceed(false);
        setSelectedButton('');
        setLoading(false);
    };
    const handleProceedButtonClick = async () => {
        if (canProceed && selectedButton) {
            const selectedType = emergencyTypes.find((type) => type.name === selectedButton);
            if (selectedType && location.latitude && location.longitude) {
                try {
                    await fetchEmergencyBooking(location.latitude, location.longitude, selectedType.id);
                    setLoading(true);
                    setIsTimerActive(true); // Start timer when the button is clicked
                    startTimer(); // Start countdown
                    
                } catch (error) {
                    console.error('Error fetching emergency booking:', error);
                    handleNoAmbulanceFound();
                } finally {
                    setLoading(false);
                }
            } else {
                console.error('Location or emergency type not available');
            }
        }
    };

    const fetchEmergencyBooking = async (latitude, longitude, typeId) => {
        try {
            const token = await AsyncStorage.getItem('token');
            setLoading(true);
    
            const response = await axios.post(
                EMERGENCY_BOOK,
                { latitude, longitude, emergency_type_id: typeId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            if (response.status === 200) {
                const { data } = response;
    
                if (data?.message === 'Data generated') {
                    const bookingId = JSON.parse(data?.data?.notification?.message?.data?.booking)?.booking_id;
    
                    if (bookingId) {
                        // Initialize the timer state
                        setTimer(120); // Set the timer to 90 seconds
                        setIsTimerActive(true); // Start the timer
    
                        const startTimer = () => {
                            const interval = setInterval(() => {
                                setTimer((prevTimer) => {
                                    if (prevTimer <= 90) { // Change to <= 1 to navigate at 0
                                        clearInterval(interval); // Stop the countdown
                                        setIsTimerActive(false); // Stop the timer
                                        navigateToDriver(bookingId); // Navigate to driver
                                        return 0; // Ensure timer shows 0
                                    }
                                    return prevTimer - 1; // Decrement the timer
                                });
                            }, 1000); // Update every second
    
                            return interval; // Return the interval ID if needed
                        };
    
                        startTimer(); // Call the startTimer function
                    } else {
                        showNoAmbulancePopup();
                    }
                } else {
                    showNoAmbulancePopup();
                }
            } else {
                showNoAmbulancePopup();
            }
        } catch (error) {
            showNoAmbulancePopup();
        } finally {
            setLoading(false);
        }
    };
    
    // const navigateToDriver = (bookingId) => {
    //     localStorage.setItem('bookingId', bookingId);
    //     navigate(`/driver`);
    // };


    // const fetchEmergencyBooking = async (latitude, longitude, typeId) => {
    //     try {
    //         const token = await AsyncStorage.getItem('token');
    //         setLoading(true);

    //         const response = await axios.post(
    //             EMERGENCY_BOOK,
    //             { latitude, longitude, emergency_type_id: typeId },
    //             { headers: { Authorization: `Bearer ${token}` } }
    //         );

    //         if (response.status === 200) {
    //             const { data } = response;

    //             if (data?.message === 'Data generated') {
    //                 const bookingId = JSON.parse(data?.data?.notification?.message?.data?.booking)?.booking_id;

    //                 if (bookingId) {
    //                     const startTimer = () => {
     
    //                         const interval = setInterval(() => {
    //                             setTimer((prevTimer) => {
    //                                 if (prevTimer <= 90) {
    //                                     // Trigger booking API once timer reaches 90 seconds
    //                                     setIsTimerActive(false); // Stop the timer
    //                                     clearInterval(interval); // Stop the countdown
    //                                     navigateToDriver(bookingId);
    //                                     return prevTimer;
    //                                 }
    //                                 return prevTimer - 1;
    //                             });
    //                         }, 1000); // Update every second
    //                     };
                       
    //                 } else {
    //                     showNoAmbulancePopup();
    //                 }
    //             } else {
    //                 showNoAmbulancePopup();
    //             }
    //         } else {
    //             showNoAmbulancePopup();
    //         }
    //     } catch (error) {
    //         showNoAmbulancePopup();
    //     } finally {
    //         setLoading(false);
    //     }
    // };

   
    const navigateToDriver = (bookingId) => {
        localStorage.setItem('bookingId', bookingId);
        navigate(`/bookingdetails`);
    };

    const showNoAmbulancePopup = () => {
        setNoAmbulanceModal(true);
    };

    const startTimer = () => {
 
        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 60) {
                    // Trigger booking API once timer reaches 90 seconds
                    setIsTimerActive(false); // Stop the timer
                    clearInterval(interval); // Stop the countdown
                   
                    return prevTimer;
                }
                return prevTimer - 1;
            });
        }, 1000); // Update every second
    };

    return (
        <Container fluid className="d-flex flex-column " style={styles.container}>
 <Row className="w-100">
    <Col className="mb-5" style={styles.buttonsContainer}>
        {emergencyTypes.map((type, index) => (
            <Col
                className="mb-3 d-flex  mt-3"
                key={index}
                style={styles.buttonContainer}
            >
                <Button
                    onClick={() => handleButtonClick(type.name)}
                    className={`w-100 ${selectedButton === type.name ? 'btn-danger' : 'btn-secondary'}`}
                    size="lg"
                >
                    {type.name}
                </Button>
            </Col>
        ))}
    </Col>
</Row>

            <Row className="w-100 ">
                <Col>
                    <Button
                        onClick={handleProceedButtonClick}
                        className="w-100  btn-danger"
                        size="lg"
                        disabled={!canProceed}
                    >
                        {loading ? 'Booking...' : 'Book Now'}
                    </Button>
                </Col>
            </Row>

            {/* Modal for no ambulance found */}
            {/* <Modal
                isOpen={noAmbulanceModal}
                onRequestClose={() => setNoAmbulanceModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '40%',
                        textAlign: 'center',
                        padding: '20px',
                    },
                }}
                ariaHideApp={false}
            >
                <h2>No Ambulance Found</h2>
                <p>Sorry, we couldn't find an available ambulance at the moment.</p>
                <Button className="btn-danger mx-2" onClick={() => navigate('/private')}>
                    Back to Home
                </Button>
                <Button className="btn-secondary mx-2" onClick={() => navigate('/nearbyhospital')}>
                    Nearby Ambulance
                </Button>
            </Modal> */}
<Modal
    isOpen={noAmbulanceModal}
    onRequestClose={() => setNoAmbulanceModal(false)}
    style={{
        content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            textAlign: 'center',
            padding: '20px',
        },
    }}
    ariaHideApp={false}
>
    <h2>No Ambulance Found</h2>
    <p>Sorry, we couldn't find an available ambulance at the moment.</p>
    <Button
        className="btn-danger mx-2"
        onClick={() => {
            setNoAmbulanceModal(false); // Close the modal
            navigate('/private'); // Navigate to home
        }}
    >
        Back to Home
    </Button>
    <Button
        className="btn-secondary mx-2"
        onClick={() => {
            setNoAmbulanceModal(false); // Close the modal
            navigate('/nearbyhospital'); // Navigate to nearby hospital
        }}
    >
        Nearby Ambulance
    </Button>
</Modal>

            {/* Loading spinner overlay */}
            {isTimerActive && (
                <Modal
                    isOpen={isTimerActive}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                        },
                    }}
                    ariaHideApp={false}
                >
                    
                    <br></br>
<div className="timer-display" style={styles.timerDisplay}>
    

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <img src={require('./assets/timer-icon.png')} alt="Timer Icon" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
</div>
<br></br>

    <p style={styles.timerText} >{timer} seconds remaining</p>
</div>

                    <p>Wait for a while Booking in progress...</p>

                    <Button className="btn-danger" onClick={handleCancelBooking}>
                        Cancel Booking
                    </Button>
                </Modal>
            )}

            {/* Modal for no ambulance found */}
            {/* <Modal
                isOpen={noAmbulanceModal}
                onRequestClose={() => setNoAmbulanceModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '40%',
                        textAlign: 'center',
                        padding: '20px',
                    },
                }}
                ariaHideApp={false}
            >
                <h2>No Ambulance Found</h2>
                <p>Sorry, we couldn't find an available ambulance at the moment.</p>
                <Button className="btn-danger mx-2" onClick={() => navigate('/private')}>
                    Back to Home
                </Button>
                <Button className="btn-secondary mx-2" onClick={() => navigate('/nearbyhospital')}>
                    Nearby Ambulance
                </Button>
            </Modal> */}

<Modal
    isOpen={noAmbulanceModal}
    onRequestClose={() => setNoAmbulanceModal(false)}
    style={{
        content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            textAlign: 'center',
            padding: '20px',
        }, 
    }}
    ariaHideApp={false}
>
    <h2>No Ambulance Found</h2>
    <p>Sorry, we couldn't find an available ambulance at the moment.</p>
    <Button
        className="btn-danger mx-2"
        onClick={() => {
            setNoAmbulanceModal(false); // Close the modal
            navigate('/private'); // Navigate to home
        }}
    >
        Back to Home
    </Button>
    <Button
        className="btn-secondary mx-2"
        onClick={() => navigate('/nearbyhospital')}
    >
        Nearby Ambulance
    </Button>
</Modal>


        </Container>
    );
}

const styles = {
    container: {
        display: 'flex',
        backgroundColor: '#066951',
        backgroundImage: `url(${require('./assets/LyfGuardLogo.png')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '400px 400px',
        padding: 20,
        height:"50vh",
        width: '90%',
        boxSizing: 'border-box',
        marginRight:"20%"
    
    },
    buttonsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        marginBottom: 10,
        width: '100%',
    },
    button: {
        fontSize: 25,
        fontWeight: 500,
        width: '100%',
        border: '2px solid black',
        borderRadius: '10px',
        marginTop: "7%",
    },
    proceedButton: {
        fontSize: 25,
        fontWeight: '500',
        width: '100%',
        border: '2px solid black',
        borderRadius: '10px',
    },
    modalStyle: {
        content: {
            top: '50%',
            left: '45%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            color: 'white',
            borderRadius: '10px',
            textAlign: 'center',
            width: '40%',
            backgroundColor: 'rgba(6, 105, 81, 0.9)',
        },
    },
    loadingModalStyle: {
        content: {
            top: '50%',
            left: '45%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
        },
    },
    modalButton: {
        marginTop: '15px',
        padding: '10px 30px',
        fontSize: '18px',
        cursor: 'pointer',
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        marginRight: '10px',
    },
    '@media (max-width: 768px)': {
        container: {
            top: '15%',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '90%',
            padding: 10,
            backgroundSize: '300px 300px',
        },
        button: {
            fontSize: 20,
        },
        proceedButton: {
            fontSize: 20,
        },
        modalStyle: {
            content: {
                width: '80%',
                padding: '15px',
            },
        },
        loadingModalStyle: {
            content: {
                width: '80%',
                padding: '15px',
            },
        },
    },
   '@media (max-width: 480px)': {
    container: {
        top: '10%',
        left: 'auto',
        right: '2%', // Move it closer to the right side
        transform: 'translate(0, 0)', // Remove centering
        width: '95%',
        backgroundSize: '200px 200px',
    },
    button: {
        fontSize: 18,
    },
    proceedButton: {
        fontSize: 18,
    },
},

};

export default EmergencyScreen;


