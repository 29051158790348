import FooterScreen from './footer';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useState, useRef, contactRef , useEffect} from 'react';
import FloatingButton from './FloatingButton';
import Navigation from './navbar';
// import Navigation from './navbar';

const AccountDeletion = () => {

    const [hoveredStates, setHoveredStates] = useState([false, false, false]);
    
    const sliderRef = useRef(null);
    const overViewRef = useRef(null);
    const teamRef = useRef(null);
    const contactRef = useRef(null);
    const handleMouseEnter = (index) => {
        const updatedHoveredStates = [...hoveredStates];
        updatedHoveredStates[index] = true;
        setHoveredStates(updatedHoveredStates);
    };

    const handleMouseLeave = (index) => {
        const updatedHoveredStates = [...hoveredStates];
        updatedHoveredStates[index] = false;
        setHoveredStates(updatedHoveredStates);
    };
    useEffect(() => {
        if (contactRef.current) {
          contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, []);
      
      
      
       
    return (

        <div ref={contactRef}>
            {/* <Navigation/> */}
            <FloatingButton />
            <Navigation
      sliderRef={sliderRef}
      overViewRef={overViewRef}
      teamRef={contactRef}
      contactRef={contactRef}
      />


            <section  style={styles.section1}>
                <img
                    src={require('./assets/LyfGurad-white-logo.png')} // Replace with your image path
                    alt="Top Left"
                    style={styles.topLeftImage}
                />

                <h3 style={styles.secheading} >Account Deletion</h3>
                <div style={styles.row}>
                    {/* <h5>Home</h5> */}
                    <a href="/" style={{ color: 'red' }}>
  <h5 >Home</h5>
</a>


                    <h5 >Account Deletion</h5>
                </div>
            </section>

            <div style={styles.heading}>
                <h3>Account Deletion</h3>
            </div>

            <div style={{ margin: '60px', fontSize: '14px', // Default font size for web
        textAlign: 'justify', // Justify text alignment
        lineHeight: '1.3', // Line height for readability
        marginLeft:"13%",
        marginRight:"13%"}}>
            <h5>
                  At LYFGUARD, we value your privacy and respect your right to control your personal information. If you wish to delete your LYFGUARD account, please follow the instructions below.
                </h5>
                <p>How to Delete Your Account</p>
                <h6>1. Log In to Your Account:</h6>
                <p>Ensure you are logged into the LYFGUARD platform using the same account you wish to delete.</p>
                <h6>2. Submit an Account Deletion Request:</h6>
                <p>Visit the Account Settings section of your LYFGUARD app or portal.</p>
                <p>Select the option "Delete My Account" and follow the instructions.</p>
                <p>
                Alternatively, you can submit a deletion request using the form below:</p>
                <p>Required Information:</p>
                <p>Registered Email Address</p>
                <p>Phone Number (if applicable)</p>
                <p>Reason for Account Deletion (optional)</p>
                <h6>3. Processing Your Request:</h6>
                <p>Once we receive your request, we will process it within 7 working days.</p>
                <p>You will receive a confirmation email once your account has been successfully deleted.</p>
              


            </div>

            <Container style={styles.cardContainer}>
                <Row>
                    <Col lg={4} md={6} sm={12}>
                        <div
                            style={{
                                ...styles.card,
                                backgroundColor: hoveredStates[0] ? '#066951' : '#FFFFF7',
                            }}
                            onMouseEnter={() => handleMouseEnter(0)}
                            onMouseLeave={() => handleMouseLeave(0)}>
                            <img
                                src={require('./assets/Partnership.png')}
                                alt="Address"
                                style={styles.cardlogo}
                            />
                            <h3 style={{ ...styles.cardTitle, color: hoveredStates[0] ? '#FFFFFF' : '#000000' }}  >Address</h3>
                            <p style={{ ...styles.cardDescription, color: hoveredStates[0] ? '#FFFFFF' : '#000000' }}># 120 3rd Cross 8th Main 3rd Phase Jp Nagar Bangaluru - 560078, Karnataka, India.</p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <div
                            style={{
                                ...styles.card,
                                backgroundColor: hoveredStates[1] ? '#066951' : '#FFFFF7',
                            }}
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={() => handleMouseLeave(1)}>
                            <img
                                src={require('./assets/Partnership.png')} // Assuming this is the correct path to your image
                                alt="Instant Ambulance"
                                style={styles.cardlogo}
                            />
                            <h3 style={{ ...styles.cardTitle, color: hoveredStates[1] ? '#FFFFFF' : '#000000' }}>Call us on</h3>
                            <p style={{ ...styles.cardDescription, color: hoveredStates[1] ? '#FFFFFF' : '#000000' }}>+1800 889 1280 (Toll free)</p>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <div
                            style={{
                                ...styles.card,
                                backgroundColor: hoveredStates[2] ? '#066951' : '#FFFFF7',
                            }}
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={() => handleMouseLeave(2)}>
                            <img
                                src={require('./assets/Zerocost.png')} // Assuming this is the correct path to your image
                                alt="Zero-cost"
                                style={styles.cardlogo}
                            />
                            <h3 style={{ ...styles.cardTitle, color: hoveredStates[2] ? '#FFFFFF' : '#000000' }}>Mail at</h3>
                            <p style={{ ...styles.cardDescription, color: hoveredStates[2] ? '#FFFFFF' : '#000000' }}>reach@lyfguard.in (For Business)
                                support@lyfguard.in (For Help Center)</p>
                        </div>
                    </Col>

                </Row>
            </Container>

            <FooterScreen />
        </div>
    );
};

const styles = {
    section1: {
        display: 'flex',
        flexDirection: 'column', // Align items vertically
        justifyContent: 'center', // Center content vertically
        alignItems: 'center', // Center content horizontally
        height: '300px',
        padding: '20px',
        backgroundColor: '#066951',
        backgroundImage: `url(${require('./assets/LyfGuardLogo.png')})`,
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    secheading: {
        color: "white",
        fontSize: "45px",
        marginTop: '5%'
    },
    topLeftImage: {
        position: 'absolute',
        top: '15%',
        // right: '2%',
        width: '100px',
        height: 'auto',
        zIndex: 1,
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        marginTop: '10px',
        color: 'white',
    },
    heading: {
        marginTop: "3%",
        textAlign: 'center',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        marginTop: 20,
        cursor: 'pointer',
    },
    card: {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 10,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.2s ease',
        marginTop: '4%',
    },
    cardTitle: {
        fontSize: 22,
        fontWeight: '500',
        marginBottom: 10,
        color: 'black',
    },
    cardDescription: {
        fontSize: 15,
        color: 'black',
    },
    cardlogo: {
        height: 80,
        width: 68,
        marginBottom: '5%',
    },
    '@media (min-width: 769px)': {
    topLeftImage: {
      top: '10%'
    },
  },
};


export default AccountDeletion;
