import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const MenuComponent = ({ selectedType, handleAmbulanceSelection, handleMortuarySelection }) => {
  return (
    <Container
      fluid // Fluid container for full-width layout
      className=" py-2"
      style={{
        paddingLeft: '2rem', // Optional: Add consistent padding
        paddingRight: '2rem', // Optional: Add consistent padding
      }}
    >
      <Row className="justify-content-center g-3">
        {/* Emergency Card */}
        <Col xs={6} lg={3}>
          <Card className="text-center h-70"> {/* Change h-70 to h-100 */}
            <Card.Body>
              <Button
                onClick={() => handleAmbulanceSelection('emergency')}
                variant={selectedType === 'emergency' ? 'danger' : 'light'}
                className="w-100"
              >
                Emergency
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Private Card */}
        <Col xs={6} lg={3}>
          <Card className="text-center h-70"> {/* Change h-70 to h-100 */}
            <Card.Body>
              <Button
                onClick={() => handleAmbulanceSelection('private')}
                variant={selectedType === 'private' ? 'danger' : 'light'}
                className="w-100"
              >
                Private
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Booking History Card */}
        <Col xs={6} lg={3}>
          <Card className="text-center h-70"> {/* Change h-70 to h-100 */}
            <Card.Body>
              <Link
                to="/bookingHistory"
                onClick={handleMortuarySelection}
                className={`btn w-100 ${
                  selectedType === 'mortuary' ? 'btn-danger text-white' : 'btn-light text-dark'
                }`}
              >
                Booking History
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Logout Card */}
        <Col xs={6} lg={3}>
          <Card className="text-center h-70"> {/* Change h-70 to h-100 */}
            <Card.Body>
            <Button 
  href="/" 
  variant="light" 
  style={{
    backgroundColor: 'lightgray', 
    color: 'black', // Default text color
    width: '100%', 
    transition: 'background-color 0.3s ease, color 0.3s ease' // Add transition for text color
  }}
  onMouseEnter={(e) => {
    e.target.style.backgroundColor = 'red';
    e.target.style.color = 'white'; // Change text color on hover
  }}
  onMouseLeave={(e) => {
    e.target.style.backgroundColor = 'lightgray';
    e.target.style.color = 'black'; // Reset text color
  }}
>
  Logout
</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MenuComponent;


