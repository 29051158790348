


import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, Autocomplete, useLoadScript } from '@react-google-maps/api';
import { useLocation, useNavigate } from 'react-router-dom';

const MapScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [region, setRegion] = useState({ lat: 12.971599, lng: 77.594566, zoom: 15 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 12.971599, lng: 77.594566 });
  const [currentAddress, setCurrentAddress] = useState('');
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  const libraries = ['places'];
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU', // Your API key here
    libraries,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          updateLocation(latitude, longitude);
        },
        (error) => {
          console.error('Error fetching location:', error);
          setCurrentAddress('Error fetching location');
        }
      );
    } else {
      setCurrentAddress('Geolocation not supported by this browser.');
    }
  }, []);

  const updateLocation = async (lat, lng) => {
    setRegion({ lat, lng, zoom: 15 });
    setMarkerPosition({ lat, lng });
    const address = await fetchAddress(lat, lng);
    setCurrentAddress(address);

    localStorage.setItem('latitude', lat);
    localStorage.setItem('longitude', lng);
  };

  const fetchAddress = async (latitude, longitude) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({ location: { lat: latitude, lng: longitude } });
      return response.results[0]?.formatted_address || 'Unknown address';
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const containerStyle = {
    width: isMobile ? '100%' : '200%',
    height: isMobile ? '50vh' : '47vh',
    marginRight: isMobile ? '0' : '1%',
  
  };

  const inputStyle = {
    width: isMobile ? '100%' : '200%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop:isMobile?'0':'27%',
    marginBottom: isMobile ? '10px' : '2%',
  };

  return (
    <div style={{ position: isMobile ? 'relative' : 'fixed', top: isMobile ? '0' : '19%', right: isMobile ? '0' : '18%' }}>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={() => {
          if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            if (place.geometry) {
              const lat = place.geometry.location.lat();
              const lng = place.geometry.location.lng();
              updateLocation(lat, lng);
            }
          }
        }}
      >
        <input
          ref={inputRef}
          placeholder="Search location"
          value={currentAddress}
          onChange={(e) => setCurrentAddress(e.target.value)}
          style={inputStyle}
        />
      </Autocomplete>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={region}
        zoom={region.zoom}
      >
        <Marker
          position={markerPosition}
          draggable
          onDragEnd={(e) => {
            const lat = e.latLng.lat();
            const lng = e.latLng.lng();
            updateLocation(lat, lng);
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default MapScreen;