import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PRIVATE_AMBULANCE_AMUNITY, PRIVATE_AMBULANCE_TYPE ,PRIVATE_AMBULANCE_BOOK} from './api';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-modal';
import { Circles } from 'react-loader-spinner';

function PrivateScreen() {
    const [amenities, setAmenities] = useState([]);
    const [calculatedPrice, setCalculatedPrice] = useState(null);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedPaymentMode, setSelectedPaymentMode]=useState(null);
    const [distance,setDistance]=useState(null);
    const [noAmbulanceModal, setNoAmbulanceModal] = useState(false); 
    const [ambulanceTypes, setAmbulanceTypes] = useState([]);
    const [selectedAmenity, setSelectedAmenity] = useState('');
    const [selectedAmenityPrice, setSelectedAmenityPrice] = useState(null);
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [loading, setLoading] = useState(false);
    const [destination,setDestination] = useState('');
    const [currentAddress, setCurrentAddress] = useState(''); // Add this line
    const [destinationAddress, setDestinationAddress] = useState(''); // Add this line
    const [ source_latitude, setSourceLatitude] = useState(null);
    const [source_longitude, setSourceLongitude] = useState(null);
    const [destination_latitude, setDestinationLatitude] = useState(null);
    const [destination_longitude, setDestinationLongitude] = useState(null);
      const [timer, setTimer] = useState(120); // Start from 120 seconds
        const [isTimerActive, setIsTimerActive] = useState(false); // To track if timer is active

        let timerInterval = null;

    const [error, setError] = useState('');
    const [selectedAmenities, setSelectedAmenities] = useState([]); // To store selected amenities
    const [selectedAmbulanceTypeId, setSelectedAmbulanceTypeId] = useState(''); // To store selected ambulance type
    
    const navigate = useNavigate();
    useEffect(() => {
        fetchAmenities();
        fetchLocationFromLocalStorage();
    }, []);
    const handleShowConfirmationModal = () => {
        setIsConfirmationModalOpen(true);
    };
    
    const fetchLocationFromLocalStorage = () => {
        const currentLat = localStorage.getItem('currentLatitude');
        const currentLng = localStorage.getItem('currentLongitude');
        const destLat = localStorage.getItem('destinationLatitude');
        const destLng = localStorage.getItem('destinationLongitude');
    
        if (currentLat && currentLng) {
            setSourceLatitude(parseFloat(currentLat));
            setSourceLongitude(parseFloat(currentLng));
            // Fetch the current address based on the coordinates
            fetchAddress(parseFloat(currentLat), parseFloat(currentLng)).then(address => {
                setCurrentAddress(address);
            });
        } else {
            console.error('Error: Current location not found in localStorage');
            alert('Current location is missing. Please ensure location is enabled.');
        }
    
        // if (destLat && destLng) {
        //     setDestinationLatitude(parseFloat(destLat));
        //     setDestinationLongitude(parseFloat(destLng));
        //     // Fetch the destination address based on the coordinates
        //     fetchAddress(parseFloat(destLat), parseFloat(destLng)).then(address => {
        //         setDestinationAddress(address);
        //     });
        // } else {
        //     console.error('Error: Destination location not found in localStorage');
        //     alert('Destination location is missing. Please set a destination.');
        // }
    };



    const fetchPrivateBooking = async (
        source_latitude,
        source_longitude
    ) => {
        try {
            // Fetch destination coordinates from localStorage
            const destLat = localStorage.getItem('destinationLatitude');
            const destLng = localStorage.getItem('destinationLongitude');
            const currentLat = localStorage.getItem('currentLatitude');
            const currentLng = localStorage.getItem('currentLongitude');
    
            if (!destLat || !destLng) {
                console.error('Error: Destination location is missing.');
                alert('Please set a valid destination.');
                return;
            }
            if (!currentLat || !currentLng) {
                console.error('Error: Current location is missing.');
                alert('Please set a valid current location.');
                return;
            }
    
            const destination_latitude = parseFloat(destLat);
            const destination_longitude = parseFloat(destLng);
            const source_latitude = parseFloat(currentLat);
            const source_longitude = parseFloat(currentLng);
    
            // Validate parsed coordinates
            if (isNaN(destination_latitude) || isNaN(destination_longitude)) {
                console.error('Error: Invalid destination coordinates.');
                alert('Destination coordinates are invalid. Please check and try again.');
                return;
            }
            if (isNaN(source_latitude) || isNaN(source_longitude)) {
                console.error('Error: Invalid current coordinates.');
                alert('Current coordinates are invalid. Please check and try again.');
                return;
            }
    
            // Proceed with API call
            const token = await AsyncStorage.getItem('token');
            if (!token) {
                console.error('Error: Token not found');
                showNoAmbulancePopup();
                return;
            }
    
            setLoading(true);
            setIsTimerActive(true); // Start timer when the button is clicked
            console.log("Selected Amenity:", selectedAmenity);
            console.log("Selected Amenity Price:", selectedAmenityPrice); // Ensure price is set
    
            // Check if price is not null or undefined before sending
            if (selectedAmenityPrice === null || selectedAmenityPrice === undefined) {
                console.error("Error: Selected Amenity Price is null or undefined.");
                return; // Prevent API call if price is not set
            }
    
            const bookingData = {
                source_latitude,
                source_longitude,
                destination_latitude,
                destination_longitude,
                ambulance_type_id: selectedAmbulanceTypeId,
                payment_type: "cash",
                amenities_ids: selectedAmenity,
                payment_amount: calculatedPrice,
            };
    
            console.log("Requested Booking Data:", bookingData);
    
            const response = await axios.post(
                PRIVATE_AMBULANCE_BOOK,
                bookingData,
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            if (response.status === 200 && response.data?.message === 'Data generated') {
                const notificationData = response.data.data?.notification_data?.message?.data;
    
                if (notificationData) {
                    const booking = JSON.parse(notificationData.booking);
                    const bookingId = booking.booking_id;
    
                    if (bookingId) {
                        setTimer(120); // Set the timer to 120 seconds
                        setIsTimerActive(true); // Start the timer
    
                        const interval = setInterval(() => {
                            setTimer((prevTimer) => {
                                if (prevTimer <= 75) { // Stop the countdown when the timer hits 0
                                    clearInterval(interval);
                                    setIsTimerActive(false);
                                    navigateToDriver(bookingId); // Navigate to the driver page
                                    localStorage.removeItem('destinationLatitude');
                                    localStorage.removeItem('destinationLongitude');
                                    return 0; // Ensure timer shows 0
                                }
                                return prevTimer - 1; // Decrement the timer
                            });
                        }, 1000); // Update every second
                    } else {
                        console.error('Error: Booking ID not found.');
                        handleNoAmbulanceFound();
                    }
                } else {
                    console.error('Error: Notification data missing in response.');
                    handleNoAmbulanceFound();
                }
            } else {
                console.error('Unexpected Response:', response.data);
                handleNoAmbulanceFound();
            }
        } catch (error) {
            if (error.response) {
                console.error('API Error Response:', {
                    status: error.response.status,
                    data: error.response.data,
                });
            } else if (error.request) {
                console.error('API Request Error:', error.request);
            } else {
                console.error('Unexpected Error:', error.message);
            }
            handleNoAmbulanceFound();
        } finally {
            setLoading(false);
        }
    };
    

    
    // Function to fetch address based on latitude and longitude
    const fetchAddress = async (latitude, longitude) => {
        try {
            const geocoder = new window.google.maps.Geocoder();
            const response = await geocoder.geocode({ location: { lat: latitude, lng: longitude } });
            return response.results[0]?.formatted_address || 'Unknown address';
        } catch (error) {
            console.error('Error fetching address:', error);
            return 'Error fetching address';
        }
    };
    
const fetchAmenities = async () => {
    try {
        const token = await AsyncStorage.getItem('token');
        const response = await axios.get(PRIVATE_AMBULANCE_AMUNITY, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Get amenities directly from the response and log them
        const amenities = response.data.data.amenities || [];
        console.log("response of amenities", response);
        console.log("Amenities List:", amenities);

        // Display prices and names
        amenities.forEach((amenity) => {
            console.log(`AmenityId: ${amenity.id},AmenityName: ${amenity.name}, Price: ${amenity.price}`);
        });

        // Optionally, set the amenities state after logging
        setAmenities(amenities);
    } catch (err) {
        console.error('Error fetching amenities:', err);
    }
};

    
    const calculateAmbulancePrice = (distance, rates) => {
        const {
            zero_five_km_rate,
            five_fifteen_km_rate,
            fifteen_thirty_km_rate,
            thirty_above_km_rate,
        } = rates;
    
        let totalPrice = 0;
    
        if (distance <= 5) {
            // Fixed price for 0–5 km
            totalPrice = parseFloat(zero_five_km_rate);
        } else if (distance <= 15) {
            // Cumulative price for 5–15 km
            totalPrice =
                parseFloat(zero_five_km_rate) +
                parseFloat(five_fifteen_km_rate);
        } else if (distance <= 30) {
            // Cumulative price for 15–30 km
            totalPrice =
                parseFloat(zero_five_km_rate) +
                parseFloat(five_fifteen_km_rate) +
                parseFloat(fifteen_thirty_km_rate);
        } else {
            // Cumulative price for 30+ km
            const additionalDistance = distance - 30;
            totalPrice =
                parseFloat(zero_five_km_rate) +
                parseFloat(five_fifteen_km_rate) +
                parseFloat(fifteen_thirty_km_rate) +
                additionalDistance * parseFloat(thirty_above_km_rate); // Add this incrementally per km above 30 km
        }
    
        return totalPrice.toFixed(2); // Return total price as a string with 2 decimal points
    };
    const fetchAmbulanceTypes = async (selectedId, selectedAmenityPrice = 0) => {
        try {
            setError('');
            setAmbulanceTypes([]);
            setLoading(true);
    
            // Fetch token from localStorage
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authorization token not found.');
            }
    
            // API request
            const response = await fetch(PRIVATE_AMBULANCE_TYPE, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amenities: selectedId }),
            });
    
            // Handle non-OK response
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error fetching ambulance types');
            }
    
            const data = await response.json();
            console.log("data",data);
            const types = data?.data?.types || [];
            setAmbulanceTypes(types);
    
            if (!types.length) {
                setError('No Ambulance Found');
                return;
            }
    
            // Get location data
            const currentLatitude = parseFloat(localStorage.getItem('currentLatitude'));
            const currentLongitude = parseFloat(localStorage.getItem('currentLongitude'));
            const destinationLatitude = parseFloat(localStorage.getItem('destinationLatitude'));
            const destinationLongitude = parseFloat(localStorage.getItem('destinationLongitude'));
    
            // Validate coordinates
            if (
                isNaN(currentLatitude) ||
                isNaN(currentLongitude) ||
                isNaN(destinationLatitude) ||
                isNaN(destinationLongitude)
            ) {
                throw new Error('Invalid location data. Please ensure all coordinates are set.');
            }
    
            // Calculate distance
            const distance = calculateDistance(
                currentLatitude,
                currentLongitude,
                destinationLatitude,
                destinationLongitude
            );
            console.log(`Distance: ${distance.toFixed(2)} km`);
            setDistance(distance);

            const basePrice = parseFloat(calculateAmbulancePrice(distance, types[0])); // Ensure base price is a float

// Parse and ensure amenity price is a valid float
const amenityPrice = parseFloat(selectedAmenityPrice) || 0; // Use parseFloat to handle decimals correctly

// Calculate the total price
const totalPrice = basePrice + amenityPrice;

// Log all the intermediate steps for debugging
console.log(`Base Price: ₹${basePrice}`);
console.log(`Amenity Price: ₹${amenityPrice}`);
console.log(`Total Price: ₹${totalPrice}`);

// Update state with the calculated price
setCalculatedPrice(totalPrice);
    
            // Update state with the calculated price
           
        } catch (err) {
            console.error('Error fetching ambulance types:', err.message);
            setError(err.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };
    
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const toRadians = (degrees) => degrees * (Math.PI / 180);
        const R = 6371; // Radius of Earth in kilometers
    
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
    
        const a = Math.sin(dLat / 2) ** 2 +
                  Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                  Math.sin(dLon / 2) ** 2;
    
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        return R * c; // Distance in kilometers
    };
    const handleAmenityChange = (e) => {
        const selectedId = e.target.value;
        setSelectedAmenity(selectedId);
    
        // Find the selected amenity by its ID
        const selectedAmenityObj = amenities.find(amenity => amenity.id.toString() === selectedId);
    
        // Set the price of the selected amenity
        const amenityPrice = selectedAmenityObj ? selectedAmenityObj.price : 0;
        setSelectedAmenityPrice(amenityPrice);
    
        // Call fetchAmbulanceTypes with the selected amenity ID and price
        fetchAmbulanceTypes(selectedId, amenityPrice);
    };
      
    const handleNoAmbulanceFound = () => {
    //    localStorage.removeItem('destinationLatitude');
    // localStorage.removeItem('destinationLongitude');
        setLoading(false);
        setNoAmbulanceModal(true);
    };
    const handleAmbulanceTypeChange = (e) => {
        const selectedId = e.target.value;
        setSelectedAmbulanceTypeId(selectedId); // Update the state
        console.log('Selected Ambulance Type ID:', selectedId);
    };
    const handleButtonClick = (slug) => {
        setSelectedButton(slug);
        setCanProceed(true);
    };
    const handleCancelBooking = () => {
        // Stop the timer and reset states
        clearInterval(timerInterval);
        setIsTimerActive(false);
        setTimer(120); // Reset timer to 120 seconds
       
      
        setLoading(false);
    };

   

    // Function to navigate to the driver's page with booking_id
    const navigateToDriver = (bookingId) => {
        // Print booking_id within the navigation function
        console.log('Navigating to driver with Booking ID:', bookingId);
    
        // Save the bookingId to localStorage
        localStorage.setItem('bookingId', bookingId);
    
        // Replace with the actual driver page navigation
        navigate(`/pvtbookingdetails`);
    };
    
    const startTimer = () => {
 
        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 60) {
                    // Trigger booking API once timer reaches 90 seconds
                    setIsTimerActive(false); // Stop the timer
                    clearInterval(interval); // Stop the countdown
                   
                    return prevTimer;
                }
                return prevTimer - 1;
            });
        }, 1000); // Update every second
    };
    // Function to handle "No Ambulance Found" state
    const showNoAmbulancePopup = () => {
        setNoAmbulanceModal(true);  // Show the modal
    };
    const handlePaymentModeChange = (e) => {
        const selectedMode = e.target.value;
        setSelectedPaymentMode(selectedMode); // Update the state for selected payment mode
    };
    
    return (
        <Container fluid className="d-flex flex-column align-items-center " style={styles.container}>
            <label style={{ color: 'white',fontSize: '21px',fontWeight: 'bold'}} htmlFor="amenitiesDropdown">Select Amenity and Ambulance:</label>
            
            {distance !== null && (
    <div style={{ marginTop: '20px', color: 'white' }}>
        <strong>Distance: </strong> {distance.toFixed(2)} km
    </div>
)}
            
            <select
                id="amenitiesDropdown"
                value={selectedAmenity}
                onChange={handleAmenityChange}
                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
            >
                <option value="" disabled>-- Select an Amenity --</option>
                {amenities.map((amenity) => (
                    <option key={amenity.id} value={amenity.id}>
                        {amenity.name} - ₹ {amenity.price}
                    </option>
                ))}
            </select>
           
            <div style={{ margin: '10px 0' }}>
    {loading ? (
        <p style={{ color: 'white' }}>Loading ambulance types...</p>
    ) : error ? (
        <p style={{ color: 'white' }}>{error}</p>
    ) : (
        ambulanceTypes.length > 0 && (
            <select
                style={{ padding: '10px', width: '250%',paddingLeft: '20px',marginLeft: '-75%' }}
                onChange={handleAmbulanceTypeChange}
                value={selectedAmbulanceTypeId || ""}
            >
                <option value="">-- Select an Ambulance Type --</option>
                {ambulanceTypes.map((type) => (
                    <option key={type.type_id} value={type.type_id}>
                        {type.ambulance_name}
                    </option>
                ))}
            </select>

        )
    )}
</div>

<div style={{ margin: '2px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    
        <>
            <span style={{ color: 'white', fontWeight: 'bold', marginRight: '10px',fontSize: '20px'  }}>Payment Mode:</span>
            <span style={{ color: 'white',fontSize: '18px'}}>Offline</span>
        </>
   
</div>

{calculatedPrice !== null && (
        <div style={{ marginTop: '20px', color: 'white', fontSize: '23px' }}>
    <strong>Total Price: </strong> ₹ {calculatedPrice}
</div>

    )}

{selectedAmenity && ambulanceTypes && (
    <button
        onClick={(e) => {
            e.preventDefault();
            handleShowConfirmationModal(); // Show the confirmation modal
        }}
        style={{
            padding: '10px',
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '100%',
            marginTop: '20px',
            opacity: (!selectedAmenity || !selectedAmbulanceTypeId || error || ambulanceTypes.length === 0) ? 0.5 : 1,
        }}
    >
        Confirm the Booking
    </button>
)}

<Modal
    isOpen={isConfirmationModalOpen}
    onRequestClose={() => setIsConfirmationModalOpen(false)}
    style={{
        content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: '80%', // Set a maximum height
            overflow: 'hidden', // Prevent scrollbars
            textAlign: 'center',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: '#f8f9fa', // Light background color
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Subtle shadow
        },
    }}
    ariaHideApp={false}
>
    <h2 style={{ marginTop: '25px', color: '#343a40', fontWeight: 'bold' }}>Confirm Your Booking</h2>
    
    <div style={{ margin: '20px 0' }}>
        <div style={{
            backgroundColor: '#e9ecef', // Light gray background
            borderRadius: '8px', // Rounded corners
            padding: '15px', // Padding inside the box
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            marginBottom: '10px' // Space between boxes
        }}>
            <p style={{ fontSize: '18px', color: '#495057', margin: '0' }}>
                <strong>Amenity:</strong> {amenities.find(amenity => amenity.id.toString() === selectedAmenity)?.name || 'N/A'}
            </p>
        </div>

        <div style={{
            backgroundColor: '#e9ecef', // Light gray background
            borderRadius: '8px', // Rounded corners
            padding: '15px', // Padding inside the box
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            marginBottom: '10px' // Space between boxes
        }}>
            <p style={{ fontSize: '18px', color: '#495057', margin: '0' }}>
                <strong>Ambulance Type:</strong> {ambulanceTypes.find(type => type.type_id.toString() === selectedAmbulanceTypeId)?.ambulance_name || 'N/A'}
            </p>
        </div>

        <div style={{
            backgroundColor: '#e9ecef', // Light gray background
            borderRadius: '8px', // Rounded corners
            padding: '15px', // Padding inside the box
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            marginBottom: '10px' // Space between boxes
        }}>
            <p style={{ fontSize: '18px', color: '#495057', margin: '0' }}>
                <strong>Distance:</strong> {distance !== null ? distance.toFixed(2) : 'N/A'} km
            </p>
        </div>

        <div style={{
            backgroundColor: '#e9ecef', // Light gray background
            borderRadius: '8px', // Rounded corners
            padding: '15px', // Padding inside the box
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            marginBottom: '10px' // Space between boxes
        }}>
            <p style={{ fontSize: '18px', color: '#495057', margin: '0' }}>
                <strong>Total Price:</strong> ₹ {calculatedPrice}
            </p>
        </div>
    </div>

    <Button
      className="btn-danger"
      style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', borderRadius: '5px' }} 
      onClick={() => {
          fetchPrivateBooking(
              source_latitude,
              source_longitude,
              destination_latitude,
              destination_longitude
          );
          setIsConfirmationModalOpen(false); // Close the modal after booking
      }}
    >
         Book Now
    </Button>
    <Button
        className="btn-secondary"
        style={{ marginTop: '20px', marginLeft: '10px',height:'45px' }}
        onClick={() => setIsConfirmationModalOpen(false)}
    >
        Back to booking
    </Button>
</Modal>
<Modal
    isOpen={noAmbulanceModal}
    onRequestClose={() => setNoAmbulanceModal(false)}
    style={{
        content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            textAlign: 'center',
            padding: '20px',
        },
    }}
    ariaHideApp={false}
>
    <h2>No Ambulance Found</h2>
    <p>Sorry, we couldn't find an available ambulance at the moment.</p>
    <Button
        className="btn-danger mx-2"
        onClick={() => {
            setNoAmbulanceModal(false); // Close the modal
            navigate('/private'); // Navigate to home
        }}
    >
        Back to Home
    </Button>
    <Button
        className="btn-secondary mx-2"
        onClick={() => {
            setNoAmbulanceModal(false); // Close the modal
            navigate('/nearbyhospital'); // Navigate to nearby hospital
        }}
    >
        Nearby Ambulance
    </Button>
</Modal>

            {/* Loading spinner overlay */}
            {isTimerActive && (
                <Modal
                    isOpen={isTimerActive}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                        },
                    }}
                    ariaHideApp={false}
                >
                    
                    <br></br>
<div className="timer-display" style={styles.timerDisplay}>
    

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <img src={require('./assets/timer-icon.png')} alt="Timer Icon" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
</div>
<br></br>

    <p style={styles.timerText} >{timer} seconds remaining</p>
</div>

                    <p>Wait for a while Booking in progress...</p>

                    <Button className="btn-danger" onClick={handleCancelBooking}>
                        Cancel Booking
                    </Button>
                </Modal>
            )}

<Modal
    isOpen={noAmbulanceModal}
    onRequestClose={() => setNoAmbulanceModal(false)}
    style={{
        content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            textAlign: 'center',
            padding: '20px',
        }, 
    }}
    ariaHideApp={false}
>
    <h2>No Ambulance Found</h2>
    <p>Sorry, we couldn't find an available ambulance at the moment.</p>
    <Button
        className="btn-danger mx-2"
        onClick={() => {
            setNoAmbulanceModal(false); // Close the modal
            navigate('/private'); // Navigate to home
        }}
    >
        Back to Home
    </Button>
    <Button
        className="btn-secondary mx-2"
        onClick={() => navigate('/nearbyhospital')}
    >
        Nearby Ambulance
    </Button>
</Modal>
        </Container>
    );
}

const styles = {
    container: {
        display: 'flex',
        backgroundColor: '#066951',
        backgroundImage: `url(${require('./assets/LyfGuardLogo.png')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '400px 400px',
        padding: 20,
        height:"69vh",
        // transform: 'translateX(-7%)',
        marginTop:'-2%',
        width: '90%',
         marginRight:"20%",
        boxSizing: 'border-box',
    
    },
    buttonsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        marginBottom: 10,
        width: '100%',
    },
    button: {
        fontSize: 25,
        fontWeight: 500,
        width: '100%',
        border: '2px solid black',
        borderRadius: '10px',
        marginTop: "7%",
    },
    proceedButton: {
        fontSize: 25,
        fontWeight: '500',
        width: '100%',
        border: '2px solid black',
        borderRadius: '10px',
    },
    modalStyle: {
        content: {
            top: '50%',
            left: '45%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            color: 'white', 
            borderRadius: '10px',
            textAlign: 'center',
            width: '40%',
            backgroundColor: 'rgba(6, 105, 81, 0.9)', 
        },
    },
    loadingModalStyle: {
        content: {
            top: '50%',
            left: '45%', // Adjusted to move slightly to the left
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
        },


        
    },
   
};

export default PrivateScreen;
