// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .custom-joinus-container {
  max-width: 100vw;
  padding: 2rem;
  background-color: rgb(6, 105, 81);
  background-image: url('../screens/assets/LyfGuardLogo.png');
  background-size: cover;
  background-position: center;
  font-style: 'roboto';
} */

.custom-card {
  margin-bottom: 2rem;
}

.center-button {
  display: flex;
  justify-content: center;
}

.submit-button {
  width: 100%;
  max-width: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/joinus.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;AAEH;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["/* .custom-joinus-container {\r\n  max-width: 100vw;\r\n  padding: 2rem;\r\n  background-color: rgb(6, 105, 81);\r\n  background-image: url('../screens/assets/LyfGuardLogo.png');\r\n  background-size: cover;\r\n  background-position: center;\r\n  font-style: 'roboto';\r\n} */\r\n\r\n.custom-card {\r\n  margin-bottom: 2rem;\r\n}\r\n\r\n.center-button {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.submit-button {\r\n  width: 100%;\r\n  max-width: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
