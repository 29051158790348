import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import BookingScreen from './screens/login';
import OTPScreen from './screens/otp';
import Moreservices from './screens/moreservices';
import EmergencyScreen from './screens/emergency';
import MapScreenPrivate from './screens/MapPrivate';
import DriverScreen from './screens/driver';
import ServiceDetail from './screens/policedetailes';

import ProfileScreen from './screens/profile';
import JoinUsScreen from './screens/joinus';
import FirstAidservices from './screens/firstaidcategory';
import FirstAidServiceDetail from './screens/firstaidcategorybyid';
import PrivacyScreen from './screens/policy';
import TermsandConditionScreen from './screens/terms&condition';
// import PrivateAmbulance from './screens/private_ambulance';
import MapComponent from './screens/googlemap';
import PrivateScreen from './screens/Privateambulancebooking';
import Aboutus from './screens/Aboutus';
 import DriverScreenPrivate from './screens/Contact'
import Contactus from './screens/Contactus';
import Maprofile from './screens/Maprofile';
import AccountDeletion from "./screens/AccountDeletion";
import PreferredHospitalsScreen from "./screens/Nearbyhospitals";
import BookingHistory from "./screens/BookingHistory";
import MenuComponent from "./screens/MenuComponent";
import FeedbackScreen from  "./screens/FloatingButton";


function App() {
  return (
    <Router>
      <Routes>
      
        <Route exact path="/" element={<Home />} />
        <Route path="/booking" element={<BookingScreen />} />
        <Route path="/otp" element={<OTPScreen />} />
        <Route path="/moreServices" element={<Moreservices />} />
        <Route path="/SelectEmergency" element={<EmergencyScreen />} />
        <Route path="/mapscreenprivate" element={<MapScreenPrivate />} />
        <Route path="/bookingdetails" element={<DriverScreen />} />
        <Route path="/service-detail/:id/:slug" element={<ServiceDetail />} />
        <Route path="/nearbyhospital" element={<PreferredHospitalsScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/join" element={<JoinUsScreen />} />
        <Route path="/firstaid" element={<FirstAidservices />} />
        <Route path="/firstaidservice/:id/:slug" element={<FirstAidServiceDetail />} />
        <Route path="/privacy" element={<PrivacyScreen/>} />
        <Route path="/tc" element={<TermsandConditionScreen/>} />
        {/* <Route path="/private" element={<PrivateAmbulance/>} /> */}
        <Route path="/private" element={<MapComponent />} />
        <Route path="/privateambulance" element={<PrivateScreen />} />
        <Route path="/firstaid" element={<FirstAidservices />} />
        <Route path="/aboutus" element={<Aboutus />} />
         <Route path="/pvtbookingdetails" element={<DriverScreenPrivate/>} /> 
        <Route path="/contactus" element={<Contactus/>} />
        <Route path="/maprofile" element={<Maprofile/>} />
        <Route path="/accountdeletion" element={<AccountDeletion/>}/>
        <Route path="/bookingHistory" element={<BookingHistory/>}/>
        <Route path="/menuComponent" element={<MenuComponent/>}/>
        <Route path="/feedback" element={<FeedbackScreen/>}/>
        
      </Routes>
    </Router>
  );
}

export default App;
