// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/LyfGuardLogo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
  background-color: rgb(6, 105, 81); /* Background color */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Background image */
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  color: white; /* Text color */
  padding: 20px; /* Adds padding inside the div */
  text-align: center; /* Centers the text */
  height: 200px; /* Set the height you need */
}
.card{
 
  margin-bottom: 10%;
}
.overlay-img {
  top: 10%;
  left: 10%;
  transform: translate(-5%, -5%);
}
.movable-card {
  width: 100%;
  position: relative;
  margin: 0 auto;
  animation: move 10s infinite alternate ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.movable-card:hover {
  transform: scale(1.05);
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/Aboutus.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC,EAAE,qBAAqB;EACxD,yDAAkD,EAAE,qBAAqB;EACzE,sBAAsB,EAAE,4CAA4C;EACpE,2BAA2B,EAAE,sBAAsB;EACnD,4BAA4B,EAAE,sCAAsC;EACpE,YAAY,EAAE,eAAe;EAC7B,aAAa,EAAE,gCAAgC;EAC/C,kBAAkB,EAAE,qBAAqB;EACzC,aAAa,EAAE,4BAA4B;AAC7C;AACA;;EAEE,kBAAkB;AACpB;AACA;EACE,QAAQ;EACR,SAAS;EACT,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,kDAAkD;EAClD,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".about-us {\r\n  background-color: rgb(6, 105, 81); /* Background color */\r\n  background-image: url('./assets/LyfGuardLogo.png'); /* Background image */\r\n  background-size: cover; /* Ensures the image covers the entire div */\r\n  background-position: center; /* Centers the image */\r\n  background-repeat: no-repeat; /* Prevents the image from repeating */\r\n  color: white; /* Text color */\r\n  padding: 20px; /* Adds padding inside the div */\r\n  text-align: center; /* Centers the text */\r\n  height: 200px; /* Set the height you need */\r\n}\r\n.card{\r\n \r\n  margin-bottom: 10%;\r\n}\r\n.overlay-img {\r\n  top: 10%;\r\n  left: 10%;\r\n  transform: translate(-5%, -5%);\r\n}\r\n.movable-card {\r\n  width: 100%;\r\n  position: relative;\r\n  margin: 0 auto;\r\n  animation: move 10s infinite alternate ease-in-out;\r\n  transition: transform 0.5s ease-in-out;\r\n}\r\n\r\n.movable-card:hover {\r\n  transform: scale(1.05);\r\n}\r\n\r\n@keyframes move {\r\n  0% {\r\n    transform: translateY(0);\r\n  }\r\n  50% {\r\n    transform: translateY(-10px);\r\n  }\r\n  100% {\r\n    transform: translateY(0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
