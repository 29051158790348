// import React, { useState, useEffect, useRef } from 'react';
// import { GoogleMap, Marker, Autocomplete, useLoadScript, DirectionsRenderer } from '@react-google-maps/api';

// const containerStyle = {
//   width: '180%',
//   height: '53vh',
//   marginRight: '1%',
// };

// const libraries = ['places'];

// const MapScreenPrivate = () => {
//   const [region, setRegion] = useState({ lat: 12.971599, lng: 77.594566, zoom: 15 });
//   const [markerPosition, setMarkerPosition] = useState({ lat: 12.971599, lng: 77.594566 });
//   const [currentAddress, setCurrentAddress] = useState('');
//   const [destinationAddress, setDestinationAddress] = useState('');
//   const [destinationPosition, setDestinationPosition] = useState(null);
//   const [directions, setDirections] = useState(null);
//   const autocompleteRef = useRef(null);
//   const destinationAutocompleteRef = useRef(null);
//   const inputRef = useRef(null);
//   const destinationInputRef = useRef(null);
  

//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: 'AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU', // Your API key here
//     libraries,
//   });

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           updateLocation(latitude, longitude); // Automatically set location when the page loads
//         },
//         (error) => {
//           console.error('Error fetching location:', error);
//           setCurrentAddress('Error fetching location');
//         }
//       );
//     } else {
//       setCurrentAddress('Geolocation not supported by this browser.');
//     }
//   }, []);
  

//   const updateLocation = async (lat, lng) => {
//     setRegion({ lat, lng, zoom: 15 }); // Update map center
//     setMarkerPosition({ lat, lng }); // Update marker position
//     const address = await fetchAddress(lat, lng);
//     setCurrentAddress(address);
  
//     // Store current address latitude and longitude in localStorage
//     localStorage.setItem('currentLatitude', lat);
//     localStorage.setItem('currentLongitude', lng);
  
//     if (destinationPosition) {
//       calculateRoute({ lat, lng }, destinationPosition);
//     }
//   };
  
//   const updateDestination = async (lat, lng) => {
//     const address = await fetchAddress(lat, lng);
//     setDestinationAddress(address);
//     setDestinationPosition({ lat, lng });
  
//     // Store destination address latitude and longitude in localStorage
//     localStorage.setItem('destinationLatitude', lat);
//     localStorage.setItem('destinationLongitude', lng);
  
//     if (markerPosition) {
//       calculateRoute(markerPosition, { lat, lng });
//     }
//   };
  

//   const fetchAddress = async (latitude, longitude) => {
//     try {
//       const geocoder = new window.google.maps.Geocoder();
//       const response = await geocoder.geocode({ location: { lat: latitude, lng: longitude } });
//       return response.results[0]?.formatted_address || 'Unknown address';
//     } catch (error) {
//       console.error('Error fetching address:', error);
//       return 'Error fetching address';
//     }
//   };

//   const calculateRoute = async (origin, destination) => {
//     try {
//       const directionsService = new window.google.maps.DirectionsService();
//       const result = await directionsService.route({
//         origin,
//         destination,
//         travelMode: window.google.maps.TravelMode.DRIVING,
//       });
//       setDirections(result);
//     } catch (error) {
//       console.error('Error calculating route:', error);
//     }
//   };

//   if (!isLoaded) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div style={{ position: 'fixed', top: '27%', right: '16%' }}>
//       <div style={{ marginBottom: '10px' }}>
//         <Autocomplete
//           onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
//           onPlaceChanged={() => {
//             if (autocompleteRef.current) {
//               const place = autocompleteRef.current.getPlace();
//               if (place.geometry) {
//                 const lat = place.geometry.location.lat();
//                 const lng = place.geometry.location.lng();
//                 updateLocation(lat, lng); // Update marker and map center
//               }
//             }
//           }}
//         >
//           <input
//             ref={inputRef}
//             placeholder="Search location"
//             value={currentAddress}
//             onChange={(e) => setCurrentAddress(e.target.value)}
//             style={{
//               width: '180%',
//               padding: '10px',
//               border: '1px solid #ccc',
//               borderRadius: '4px',
//             }}
//           />
//         </Autocomplete>
//       </div>

//       <div style={{ marginBottom: '10px' }}>
//         <Autocomplete
//           onLoad={(autocomplete) => (destinationAutocompleteRef.current = autocomplete)}
//           onPlaceChanged={() => {
//             if (destinationAutocompleteRef.current) {
//               const place = destinationAutocompleteRef.current.getPlace();
//               if (place.geometry) {
//                 const lat = place.geometry.location.lat();
//                 const lng = place.geometry.location.lng();
//                 updateDestination(lat, lng); // Update destination marker
//               }
//             }
//           }}
//         >
//           <input
//             ref={destinationInputRef}
//             placeholder="Search destination"
//             value={destinationAddress}
//             onChange={(e) => setDestinationAddress(e.target.value)}
//             style={{
//               width: '180%',
//               padding: '10px',
//               border: '1px solid #ccc',
//               borderRadius: '4px',
//             }}
//           />
//         </Autocomplete>
//       </div>

//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={region}
//         zoom={region.zoom}
//       >
//         <Marker
//           position={markerPosition}
//           draggable
//           onDragEnd={(e) => {
//             const lat = e.latLng.lat();
//             const lng = e.latLng.lng();
//             updateLocation(lat, lng);
//           }}
//         />
//         {destinationPosition && (
//           <Marker position={destinationPosition} />
//         )}
//         {directions && <DirectionsRenderer directions={directions} />}
//       </GoogleMap>
//     </div>
//   );
// };

// export default MapScreenPrivate;


import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, Autocomplete, useLoadScript, DirectionsRenderer } from '@react-google-maps/api';
import { Container, Row, Col, Form } from 'react-bootstrap';

const containerStyle = {
  width: '100%',
  height: '60vh',
};

const libraries = ['places'];

const MapScreenPrivate = () => {
  const [region, setRegion] = useState({ lat: 12.971599, lng: 77.594566, zoom: 15 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 12.971599, lng: 77.594566 });
  const [currentAddress, setCurrentAddress] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [destinationPosition, setDestinationPosition] = useState(null);
  const [directions, setDirections] = useState(null);
  const autocompleteRef = useRef(null);
  const destinationAutocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU', // Your API key here
    libraries,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          updateLocation(latitude, longitude); // Automatically set location when the page loads
        },
        (error) => {
          console.error('Error fetching location:', error);
          setCurrentAddress('Error fetching location');
        }
      );
    } else {
      setCurrentAddress('Geolocation not supported by this browser.');
    }
  }, []);
  

  // const updateLocation = async (lat, lng) => {
  //   setRegion({ lat, lng, zoom: 15 }); // Update map center
  //   setMarkerPosition({ lat, lng }); // Update marker position
  //   const address = await fetchAddress(lat, lng);
  //   setCurrentAddress(address);
  
  //   // Store current address latitude and longitude in localStorage
  //   localStorage.setItem('currentLatitude', lat);
  //   localStorage.setItem('currentLongitude', lng);
  
  //   if (destinationPosition) {
  //     calculateRoute({ lat, lng }, destinationPosition);
  //   }
  // };
  
  // const updateDestination = async (lat, lng) => {
  //   const address = await fetchAddress(lat, lng);
  //   setDestinationAddress(address);
  //   setDestinationPosition({ lat, lng });
  
  //   // Store destination address latitude and longitude in localStorage
  //   localStorage.setItem('destinationLatitude', lat);
  //   localStorage.setItem('destinationLongitude', lng);
  
  //   if (markerPosition) {
  //     calculateRoute(markerPosition, { lat, lng });
  //   }
  // };
  



  const updateLocation = async (lat, lng) => {
    setRegion({ lat, lng, zoom: 15 }); // Update map center
    setMarkerPosition({ lat, lng }); // Update marker position
    const address = await fetchAddress(lat, lng);
    setCurrentAddress(address);

    // Store current address latitude and longitude in localStorage
    localStorage.setItem('currentLatitude', lat);
    localStorage.setItem('currentLongitude', lng);

    if (destinationPosition) {
        calculateRoute({ lat, lng }, destinationPosition);
    }
};

const updateDestination = async (lat, lng) => {
    const address = await fetchAddress(lat, lng);
    setDestinationAddress(address);
    setDestinationPosition({ lat, lng });

    // Store destination address latitude and longitude in localStorage
    localStorage.setItem('destinationLatitude', lat);
    localStorage.setItem('destinationLongitude', lng);

    if (markerPosition) {
        calculateRoute(markerPosition, { lat, lng });
    }
};
  const fetchAddress = async (latitude, longitude) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({ location: { lat: latitude, lng: longitude } });
      return response.results[0]?.formatted_address || 'Unknown address';
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  };

  const calculateRoute = async (origin, destination) => {
    try {
      const directionsService = new window.google.maps.DirectionsService();
      const result = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      setDirections(result);
    } catch (error) {
      console.error('Error calculating route:', error);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const containerStyle = {
    width: isMobile ? '100%' : '200%',
    height: isMobile ? '50vh' : '47vh',
    marginRight: isMobile ? '0' : '1%',
  };
  const inputStyle = {
    width: isMobile ? '100%' : '200%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop:isMobile?'0':'16%',
    marginBottom: isMobile ? '5px' : '0',
  };
  const inputStyledest = {
    width: isMobile ? '100%' : '200%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop:isMobile?'0':'4%',
    marginBottom: isMobile ? '5px' : '2%',
  };


  return (
    <div style={{ position: isMobile ? 'relative' : 'fixed', top: isMobile ? '0' : '22%', right: isMobile ? '0' : '18%' }}>
      
         
           
            <Autocomplete
              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
              onPlaceChanged={() => {
                if (autocompleteRef.current) {
                  const place = autocompleteRef.current.getPlace();
                  if (place.geometry) {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    updateLocation(lat, lng); // Update marker and map center
                  }
                }
              }}
            >
              <Form.Control
                ref={inputRef}
                placeholder="Search location"
                value={currentAddress}
                onChange={(e) => setCurrentAddress(e.target.value)}
                style={inputStyle}

              />
            </Autocomplete>

                
            <Autocomplete
              onLoad={(autocomplete) => (destinationAutocompleteRef.current = autocomplete)}
              onPlaceChanged={() => {
                if (destinationAutocompleteRef.current) {
                  const place = destinationAutocompleteRef.current.getPlace();
                  if (place.geometry) {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    updateDestination(lat, lng); // Update destination marker
                  }
                }
              }}
            >
              <Form.Control
                ref={destinationInputRef}
                placeholder="Search destination"
                value={destinationAddress}
                onChange={(e) => setDestinationAddress(e.target.value)}
                style={inputStyledest}

              />
            </Autocomplete>
                         
            <GoogleMap
             mapContainerStyle={containerStyle}
              center={region}
              zoom={region.zoom}
            >
              <Marker
                position={markerPosition}
                draggable
                onDragEnd={(e) => {
                  const lat = e.latLng.lat();
                  const lng = e.latLng.lng();
                  updateLocation(lat, lng);
                }}
              />
              {destinationPosition && (
                <Marker position={destinationPosition} />
              )}
              {directions && <DirectionsRenderer directions={directions} />}
            </GoogleMap>
          
    </div>
  );
};

export default MapScreenPrivate;
