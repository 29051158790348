import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FeedbackScreen = () => {
    const navigate = useNavigate();
    const [hoveredButton, setHoveredButton] = useState(null);
    const [feedback, setFeedback] = useState({
        rating: 0,
        message: ''
    });
    const [errors, setErrors] = useState({
        rating: '',
        message: ''
    });
    const [submitted, setSubmitted] = useState(false);

    const handleMouseEnterButton = (button) => setHoveredButton(button);
    const handleMouseLeaveButton = () => setHoveredButton(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFeedback((prevFeedback) => ({
            ...prevFeedback,
            [name]: value
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '' // Clear error when user starts typing
        }));
    };

    const handleStarClick = (rating) => {
        setFeedback((prevFeedback) => ({
            ...prevFeedback,
            rating
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            rating: '' // Clear error when rating is selected
        }));
    };

    const validateFields = () => {
        const newErrors = {};

        if (!feedback.rating) {
            newErrors.rating = 'Rating is required';
        }
        if (!feedback.message) {
            newErrors.message = 'Message is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        if (validateFields()) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    alert('No authorization token found. Please log in again.');
                    navigate('/login'); // Redirect to login page if token is missing
                    return;
                }

                const response = await axios.post(
                    'https://api.lyfguard.in/lyfguard/public/api/booking-feedback',
                    feedback,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.status === 200) {
                    alert('Feedback submitted successfully!');
                    setFeedback({ rating: 0, message: '' }); // Clear the form after submission
                } else {
                    alert('Failed to submit feedback. Please try again.');
                }
            } catch (error) {
                console.error('Error submitting feedback:', error);
                alert('An error occurred while submitting your feedback. Please try again later.');
            }
        }
    };

    return (
        <div style={styles.centeredContainer}>
            <Container fluid style={styles.body}>
                <div style={styles.cardContainer}>
                    <div style={styles.card}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formRating" className="mb-4">
                                <Form.Label style={styles.label}>Rating</Form.Label>
                                <div style={styles.starsContainer}>
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <span
                                            key={star}
                                            style={{
                                                cursor: 'pointer',
                                                color: star <= feedback.rating ? '#FFD700' : '#ccc',
                                                fontSize: '24px'
                                            }}
                                            onClick={() => handleStarClick(star)}
                                        >
                                            ★
                                        </span>
                                    ))}
                                </div>
                                {errors.rating && (
                                    <div style={styles.errorText}>{errors.rating}</div>
                                )}
                            </Form.Group>

                            <Form.Group controlId="formMessage" className="mb-4">
                                <Form.Label style={styles.label}>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={feedback.message}
                                    onChange={handleChange}
                                    placeholder="Enter your message"
                                    isInvalid={!!errors.message}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        ...styles.joinUsButton,
                                        backgroundColor: hoveredButton === 'Submit' ? '#FFBB37' : 'red',
                                        color: hoveredButton === 'Submit' ? 'black' : 'white'
                                    }}
                                    onMouseEnter={() => handleMouseEnterButton('Submit')}
                                    onMouseLeave={handleMouseLeaveButton}
                                >
                                    SUBMIT
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default FeedbackScreen;

const styles = {
    centeredContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '70vh',
        backgroundColor: '#066951',
        backgroundImage: `url(${require('./assets/LyfGuardLogo.png')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '600px 600px'
    },
    body: {
        width: '100%',
        maxWidth: '800px',
        marginTop: '3%',
        marginBottom: '3%'
    },
    cardContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: 20,
        padding: 40,
        border: '1px solid rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(2px)',
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    label: {
        color: 'white',
        fontSize: 18
    },
    joinUsButton: {
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: 'red',
        textAlign: 'center',
        borderRadius: '10px',
        border: 'none',
        cursor: 'pointer',
        padding: '8px 16px',
        fontSize: '18px',
        marginTop: '20px'
    },
    starsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px'
    },
    errorText: {
        color: 'red',
        fontSize: '14px',
        marginTop: '5px'
    }
};