// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { PREFERRED_HOSPITAL } from './api';

// function PreferredHospitalsScreen() {
//     const [preferredHospitals, setPreferredHospitals] = useState([]);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchPreferredHospitals = async () => {
//             try {
//                 const token = localStorage.getItem('token');

//                 if (!token) {
//                     console.error('Token is missing');
//                     setError('Token is missing');
//                     return;
//                 }

//                 console.log('Token:', token);

//                 const response = await axios.get(PREFERRED_HOSPITAL, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                         'Content-Type': 'application/json',
//                     }
//                 });

//                 console.log('Response:', response);

//                 if (response.data.code === 200) {
//                     // Filter and set preferred hospitals based on `isPreferred` field
//                     const preferred = response.data.data.branches.filter(branch => branch.isPreferred === 1);
//                     setPreferredHospitals(preferred);
//                 } else {
//                     setError(response.data.message);
//                 }
//             } catch (err) {
//                 console.error('Error fetching preferred hospitals:', err);
//                 if (err.response) {
//                     console.error('Response data:', err.response.data);
//                     console.error('Response status:', err.response.status);
//                     console.error('Response headers:', err.response.headers);
//                 } else if (err.request) {
//                     console.error('Request data:', err.request);
//                 } else {
//                     console.error('Error message:', err.message);
//                 }
//                 setError('An error occurred while fetching the preferred hospitals.');
//             }
//         };

//         fetchPreferredHospitals();
//     }, []);

//     return (
//         <div>
//             <h2>Preferred Hospitals</h2>
//             {error ? (
//                 <p></p>
//                 // <p>Error: {error}</p>

//             ) : (
//                 preferredHospitals.length > 0 ? (
//                     <ul>
//                         {preferredHospitals.map(hospital => (
//                             <li key={hospital.id}>
//                                 <h3>{hospital.name}</h3>
//                                 <p>{hospital.branchTypeName}</p>
//                                 <p>{hospital.address_line1}</p>
//                                 <p>{hospital.pincode}</p>
//                                 <p>{hospital.phone}</p>
//                             </li>
//                         ))}
//                     </ul>
//                 ) : (
//                     <p>No preferred hospitals found.</p>
//                 )
//             )}
//         </div>
//     );
// }

// export default PreferredHospitalsScreen;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { PREFERRED_HOSPITAL } from './api';

// let latitude = '';
// let longitude = '';

// function PreferredHospitalsScreen() {
//     const [preferred, setPreferredHospital] = useState([]);
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [searchLocation, setSearchLocation] = useState(null);
//     const [userLocation, setUserLocation] = useState(null);
//     const [hoveredButton, setHoveredButton] = useState(null);
//     const [preferredHospitals, setPreferredHospitals] = useState([]);

//     const handleMouseEnterbutton = (button) => setHoveredButton(button);
//     const handleMouseLeavesbutton = () => setHoveredButton(null);

//     useEffect(() => {
//         const fetchPreferredHospitals = async (lat, long) => {
//             try {
//                 const token = localStorage.getItem('token');
        
//                 if (!token) {
//                     console.error('Token is missing');
//                     setError('Token is missing');
//                     setLoading(false);
//                     return;
//                 }
        
//                 console.log('Token:', token);
        
//                 const response = await axios.get(`${PREFERRED_HOSPITAL}/${lat}/${long}`, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                         'Content-Type': 'application/json',
//                     }
//                 });
        
//                 console.log('Response:', response);
        
//                 if (response.data.code === 200 && response.data.data?.branches) {
//                     const preferred = response.data.data.branches.filter(branch => branch.isPreferred === null || branch.isPreferred === 1);
//                     setPreferredHospitals(preferred);
        
//                     if (preferred.length === 0) {
//                         setError('No preferred hospitals found.');
//                     }
//                 } else {
//                     setError('No preferred hospitals found.');
//                 }
//             } catch (err) {
//                 console.error('Error fetching preferred hospitals:', err);
//                 setError('An error occurred while fetching the preferred hospitals.');
//             } finally {
//                 setLoading(false);
//             }
//         };
        
    
//         const getUserLocation = () => {
//             if (navigator.geolocation) {
//                 navigator.geolocation.getCurrentPosition(
//                     (position) => {
//                         const latitude = position.coords.latitude;
//                         const longitude = position.coords.longitude;
//                         console.log('User Location:', latitude, longitude);
//                         fetchPreferredHospitals(latitude, longitude);
//                         setUserLocation({ latitude, longitude }); // Save user location for directions
//                     },
//                     (error) => {
//                         console.error('Error fetching location:', error);
//                         setError('Failed to get user location.');
//                         setLoading(false);
//                     }
//                 );
//             } else {
//                 console.error('Geolocation is not supported by this browser.');
//                 setError('Geolocation is not supported by this browser.');
//                 setLoading(false);
//             }
//         };
    
//         getUserLocation();
//     }, []);
    
//     const handleDirectionsClick = () => {
//         if (preferred) {
//             let originLat, originLng;
    
//             if (searchLocation) {
//                 originLat = searchLocation.latitude;
//                 originLng = searchLocation.longitude;
//             } else if (userLocation) {
//                 originLat = userLocation.latitude;
//                 originLng = userLocation.longitude;
//             } else {
//                 setError('Could not determine user location');
//                 return;
//             }
    
//             const { latitude: destLat, longitude: destLng } = preferred; // Example: picking the first preferred hospital
//             const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${originLat},${originLng}&destination=${destLat},${destLng}&travelmode=driving`;
//             window.open(directionsUrl, '_blank');
//         }
//     };
    

//     const handleCallClick = () => {
//         if (preferred && preferred.phone) {
//             window.location.href = `tel:${preferred.phone}`;
//         }
//     };

//     if (error) {
//         return <div style={styles.errorContainer}>Error: {error}</div>;
//     }

//     if (!preferred) {
//         return <div></div>;
//     }

//     return (
//         <div style={styles.container}>
//             {loading ? (
//                 <p>Loading...</p>
//             ) : error ? (
//                 <p style={{ color: 'red' }}>{error}</p>
//             ) : preferredHospitals.length > 0 ? (
//                 preferredHospitals.map((preferred, index) => (
//                     <div key={index} style={styles.cardContent}>
//                         <h2 style={styles.cardTitle}>{preferred.name}</h2>
//                         <p style={styles.cardDescription}>
//                             <img src={require('./assets/location.png')} alt="Location" style={styles.Icon} />
//                             {preferred.address_line1}, {preferred.pincode}
//                         </p>
//                         <p style={styles.cardDescription}>
//                             <a href={`tel:${preferred.phone}`} style={styles.phoneLink}>
//                                 <img src={require('./assets/phone.png')} alt="Phone" style={styles.Icon} />
//                                 {preferred.phone}
//                             </a>
//                         </p>
//                         <div style={styles.buttonRow}>
//                             <button
//                                 onClick={() => handleDirectionsClick(preferred.latitude, preferred.longitude)}
//                                 style={{
//                                     ...styles.Button,
//                                     backgroundColor: hoveredButton === 'Direction' ? 'green' : '#F4A338',
                                    
//                                 }}
//                                 onMouseEnter={() => handleMouseEnterbutton('Direction')}
//                         onMouseLeave={handleMouseLeavesbutton}
//                             >
//                                 Get Directions
//                             </button>
//                             <button
//                                 onClick={() => handleCallClick(preferred.phone)}
//                                 style={{
//                                     ...styles.Button,
//                                     backgroundColor: hoveredButton === 'Call' ? 'green' : '#F4A338',
//                                 }}
//                                 onMouseEnter={() => handleMouseEnterbutton('Call')}
//                         onMouseLeave={handleMouseLeavesbutton}
//                             >
//                                 Call
//                             </button>
//                         </div>
//                     </div>
//                 ))
//             ) : (
//                 <p>No preferred hospitals available.</p>
//             )}
//         </div>
//     );
    
// }

// const styles = {
//     container: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         minHeight: '100vh',
//         backgroundColor: '#066951',
//         backgroundImage: `url(${require('./assets/LyfGuardLogo.png')})`,
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: '600px 600px',
//         padding: 20,
//     },
//     searchBar: {
//         width: '100%',
//         maxWidth: 600,
//         marginBottom: 20,
//     },
//     searchInput: {
//         width: '100%',
//         padding: 10,
//         fontSize: 16,
//         borderRadius: 5,
//         border: '1px solid #ccc',
//     },
//     cardContent: {
//         borderRadius: 15,
//         padding: '4%',
//         // width: '40%',
//         background: 'linear-gradient(135deg, #EEEEEE,#EEEEEE, #F4A338)',
//         boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//         border: '3px solid white',
        
//     },
//     cardTitle: {
//         fontSize: 24,
//         fontWeight: 700,
//         marginBottom: '1%',
//         color: 'black',
//     },
//     cardDescription: {
//         fontSize: 18,
//         color: 'black',
//     },
//     Icon: {
//         marginRight: 10,
//         marginLeft: 10,
//         marginBottom:5,
//         width: 18,
//         height: 18,
//         verticalAlign: 'middle',
//     },
//     phoneLink: {
//         color: '#007BFF',
//         textDecoration: 'none',
//     },
//     errorContainer: {
//         color: 'red',
//         fontWeight: 'bold',
//     },
//     Button: {
//         padding: '10px 20px',
//         fontSize: 20,
//         color: '#fff',
//         // backgroundColor: '#F4A338',
//         border: 'none',
//         borderRadius: 15,
//         cursor: 'pointer',
//         marginRight: 20,
//         fontWeight: '600'
//     },

//     buttonRow: {
//         display: 'flex',
//         marginTop: 20,
//     }
// };

// export default PreferredHospitalsScreen;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PREFERRED_HOSPITAL } from './api';

function PreferredHospitalsScreen() {
    const [preferredHospitals, setPreferredHospitals] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hoveredButton, setHoveredButton] = useState({});

    const handleMouseEnterButton = (index, buttonType) => {
        setHoveredButton({ index, buttonType });
    };

    const handleMouseLeaveButton = () => {
        setHoveredButton({});
    };

    const handleGetDirections = (lat, long) => {
        const url = `https://www.google.com/maps?q=${lat},${long}`;
        window.open(url, '_blank');
    };

    const handleCall = (phone) => {
        window.location.href = `tel:${phone}`;
    };

    useEffect(() => {
        const fetchPreferredHospitals = async (lat, long) => {
            try {
                const token = localStorage.getItem('token');

                if (!token) {
                    console.error('Token is missing');
                    setError('Token is missing');
                    setLoading(false);
                    return;
                }

                const response = await axios.get(`${PREFERRED_HOSPITAL}/${lat}/${long}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });

                if (response.data.code === 200 && response.data.data?.branches) {
                    const preferred = response.data.data.branches.filter(branch => branch.isPreferred === null || branch.isPreferred === 1);
                    setPreferredHospitals(preferred);

                    if (preferred.length === 0) {
                        setError('No preferred hospitals found.');
                    }
                } else {
                    setError('No preferred hospitals found.');
                }
            } catch (err) {
                console.error('Error fetching preferred hospitals:', err);
                setError('An error occurred while fetching the preferred hospitals.');
            } finally {
                setLoading(false);
            }
        };

        const getUserLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;
                        fetchPreferredHospitals(latitude, longitude);
                    },
                    (error) => {
                        console.error('Error fetching location:', error);
                        setError('Failed to get user location.');
                        setLoading(false);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
                setError('Geolocation is not supported by this browser.');
                setLoading(false);
            }
        };

        getUserLocation();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    return (
        <div style={styles.container}>
            <div><h3 style={{color:"white"}}>Nearby Hospitals</h3></div>
           <br></br>
            {preferredHospitals.map((hospital, index) => (
                <div key={index} style={styles.cardContent}>
                    <h2 style={styles.cardTitle}>{hospital.name}</h2>
                    <p style={styles.cardDescription}>
                        <img src={require('./assets/location.png')} alt="Location" style={styles.Icon} />
                        {hospital.address_line1}, {hospital.pincode}
                    </p>
                    <p style={styles.cardDescription}>
                        <a href={`tel:${hospital.phone}`} style={styles.phoneLink}>
                            <img src={require('./assets/phone.png')} alt="Phone" style={styles.Icon} />
                            {hospital.phone}
                        </a>
                    </p>
                    <div style={styles.buttonRow}>
                        <button
                            onClick={() => handleGetDirections(hospital.latitude, hospital.longitude)}
                            style={{
                                ...styles.Button,
                                backgroundColor: hoveredButton.index === index && hoveredButton.buttonType === 'Direction' ? 'green' : '#F4A338',
                            }}
                            onMouseEnter={() => handleMouseEnterButton(index, 'Direction')}
                            onMouseLeave={handleMouseLeaveButton}
                        >
                            Get Directions
                            <img src={require('./assets/Direction.png')} alt="Profile/Register" style={styles.Icons} />
                        </button>
                        <button
                            onClick={() => handleCall(hospital.phone)}
                            style={{
                                ...styles.Button,
                                backgroundColor: hoveredButton.index === index && hoveredButton.buttonType === 'Call' ? 'green' : '#F4A338',
                            }}
                            onMouseEnter={() => handleMouseEnterButton(index, 'Call')}
                            onMouseLeave={handleMouseLeaveButton}
                        >
                            Call
                            <img src={require('./assets/Call.png')} alt="Profile/Register" style={styles.Icons} />
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#066951',
        backgroundImage: `url(${require('./assets/LyfGuardLogo.png')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '1200px 1200px',
        padding: 20,
    },
    cardContent: {
        borderRadius: 15,
        padding: '4%',
        background: 'linear-gradient(135deg, #EEEEEE,#EEEEEE, #F4A338)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        border: '3px solid white',
        marginBottom: 20,
        width:"60%"
    },
    cardTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: 'black',
    },
    cardDescription: {
        fontSize: 18,
        color: 'black',
    },
    Icon: {
        marginRight: 10,
        width: 18,
        height: 18,
        verticalAlign: 'middle',
    },
    Icons: {
        marginLeft: 10,
        width: 18,
        height: 18,
        verticalAlign: 'middle',
    },
    phoneLink: {
        color: '#007BFF',
        textDecoration: 'none',
    },
    Button: {
        padding: '10px 20px',
        fontSize: 16,
        color: '#fff',
        border: 'none',
        borderRadius: 15,
        cursor: 'pointer',
        marginRight: 10,
    },
    buttonRow: {
        display: 'flex',
        marginTop: 20,
    },
};

export default PreferredHospitalsScreen;

