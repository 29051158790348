// import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./googlemap.css";
// import { useLoadScript } from "@react-google-maps/api";

// import MenuComponent from "./MenuComponent";
// import Header from "./Header";
// import MapScreen from "./Maprofile";
// import EmergencyScreen from "./emergency";
// import PrivateScreen from "./Privateambulancebooking";
// import MapScreenPrivate from "./MapPrivate";

// const libraries = ["places"];

// function MapComponent() {
//   const [selectedType, setSelectedType] = useState("emergency");
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [comingSoonMessage, setComingSoonMessage] = useState("");

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: "AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU",
//     libraries,
//   });

//   const handleAmbulanceSelection = (type) => {
//     setSelectedType(type);
//     setComingSoonMessage("");
//   };

//   const handleMortuarySelection = () => {
//     setSelectedType("mortuary");
//     setComingSoonMessage("Coming Soon ...");
//   };

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         setCurrentLocation({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       },
//       () => null
//     );
//   }, []);

//   if (loadError) return <div>Error loading map</div>;
//   if (!isLoaded) return <div>Loading map...</div>;

//   return (
//     <div className="d-flex flex-column vh-100">
//       <Header />
//       <div >
//             <MenuComponent
//               selectedType={selectedType}
//               handleAmbulanceSelection={handleAmbulanceSelection}
//               handleMortuarySelection={handleMortuarySelection}
//             />
//           </div>
//       <div className="container mt-3">
       

//           {/* Main Content */}
//           <div className="col-12 col-md-9">
//             {selectedType === "emergency" && (
//               <div className="d-flex flex-column">
//                 <div className="mb-3">
//                   <EmergencyScreen />
//                 </div>
//                 <div className="mt-3">
//                   <MapScreen />
//                 </div>
//               </div>
//             )}
//             {selectedType === "private" && (
//               <div className="d-flex flex-column">
//                 <div className="mb-3">
//                   <PrivateScreen />
//                 </div>
//                 <div className="mt-3">
//                   <MapScreenPrivate />
//                 </div>
//               </div>
//             )}
//             {selectedType === "mortuary" && comingSoonMessage && (
//               <div className="text-center text-white mt-5">
//                 <h3>{comingSoonMessage}</h3>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//   );
// }

// export default MapComponent;

// import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./googlemap.css";
// import { useLoadScript } from "@react-google-maps/api";

// import MenuComponent from "./MenuComponent";
// import Header from "./Header";
// import MapScreen from "./Maprofile";
// import EmergencyScreen from "./emergency";
// import PrivateScreen from "./Privateambulancebooking";
// import MapScreenPrivate from "./MapPrivate";

// const libraries = ["places"];

// function MapComponent() {
//   const [selectedType, setSelectedType] = useState("emergency");
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [comingSoonMessage, setComingSoonMessage] = useState("");

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: "AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU",
//     libraries,
//   });

//   const handleAmbulanceSelection = (type) => {
//     setSelectedType(type);
//     setComingSoonMessage("");
//   };

//   const handleMortuarySelection = () => {
//     setSelectedType("mortuary");
//     setComingSoonMessage("Coming Soon ...");
//   };

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         setCurrentLocation({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       },
//       () => null
//     );
//   }, []);

//   if (loadError) return <div>Error loading map</div>;
//   if (!isLoaded) return <div>Loading map...</div>;

//   return (
//     <div className="d-flex flex-column vh-100">
//       <Header />
//       <div>
//         <MenuComponent
//           selectedType={selectedType}
//           handleAmbulanceSelection={handleAmbulanceSelection}
//           handleMortuarySelection={handleMortuarySelection}
//         />
//       </div>
//       <div className="container mt-3">
//         {/* Main Content */}
//         <div className="col-12 col-md-9">
//           {/* For mobile screens */}
//           <div className="d-md-none">
//             <div className="mt-3">
//               <MapScreen />
//             </div>
//             {selectedType === "emergency" && (
//               <div className="mb-3">
//                 <EmergencyScreen />
//               </div>
//             )}
//             {selectedType === "private" && (
//               <div className="mb-3">
//                 <PrivateScreen />
//               </div>
//             )}
//           </div>

//           {/* For larger screens */}
//           <div className="d-none d-md-flex flex-column">
//             {selectedType === "emergency" && (
//               <div className="d-flex flex-column">
//                 <div className="mb-3">
//                   <EmergencyScreen />
//                 </div>
//                 <div className="mt-3">
//                   <MapScreen />
//                 </div>
//               </div>
//             )}
//             {selectedType === "private" && (
//               <div className="d-flex flex-column">
//                 <div className="mb-3">
//                   <PrivateScreen />
//                 </div>
//                 <div className="mt-3">
//                   <MapScreenPrivate />
//                 </div>
//               </div>
//             )}
//             {selectedType === "mortuary" && comingSoonMessage && (
//               <div className="text-center text-white mt-5">
//                 <h3>{comingSoonMessage}</h3>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MapComponent;

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./googlemap.css";
import { useLoadScript } from "@react-google-maps/api";

import MenuComponent from "./MenuComponent";
import Header from "./Header";
import MapScreen from "./Maprofile";
import EmergencyScreen from "./emergency";
import PrivateScreen from "./Privateambulancebooking";
import MapScreenPrivate from "./MapPrivate";

const libraries = ["places"];

function MapComponent() {
  const [selectedType, setSelectedType] = useState("emergency");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [comingSoonMessage, setComingSoonMessage] = useState("");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU",
    libraries,
  });

  const handleAmbulanceSelection = (type) => {
    setSelectedType(type);
    setComingSoonMessage("");
  };

  const handleMortuarySelection = () => {
    setSelectedType("mortuary");
    setComingSoonMessage("Coming Soon ...");
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => null
    );
  }, []);

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map...</div>;

  return (
    <div className="d-flex flex-column vh-100">
      <Header />
      <div>
        <MenuComponent
          selectedType={selectedType}
          handleAmbulanceSelection={handleAmbulanceSelection}
          handleMortuarySelection={handleMortuarySelection}
        />
      </div>
      <div className="container mt-1">
        {/* Main Content */}
        <div className="col-12 col-md-9">
          {/* For mobile screens */}
          <div className="d-md-none">
  <div className="mt-1">
    {selectedType === "private" ? <MapScreenPrivate /> : <MapScreen />}
  </div>
  
  {selectedType === "emergency" && (
    <div className="mb-3 d-flex justify-content-center" style={{ marginTop: '20px' }}>
      <EmergencyScreen />
    </div>
  )}
  
  {selectedType === "private" && (
    <div className="mb-3 d-flex justify-content-center" style={{ marginTop: '20px' }}>
      <PrivateScreen />
    </div>
  )}
</div>

          {/* For larger screens */}
          <div className="d-none d-md-flex flex-column">
            {selectedType === "emergency" && (
              <div className="d-flex flex-column">
                <div className="mb-3">
                  <EmergencyScreen />
                </div>
                <div className="mt-3">
                  <MapScreen />
                </div>
              </div>
            )}
            {selectedType === "private" && (
              <div className="d-flex flex-column">
                <div className="mb-3">
                  <PrivateScreen />
                </div>
                <div className="mt-3">
                  <MapScreenPrivate />
                </div>
              </div>
            )}
            {selectedType === "mortuary" && comingSoonMessage && (
              <div className="text-center text-white mt-5">
                <h3>{comingSoonMessage}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapComponent;