import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BOOKING_HISTORY } from './api';
import moment from 'moment';

const BookingHistory = () => {
  const [bookingHistory, setBookingHistory] = useState([]); // State for storing booking history
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [error, setError] = useState(''); // State for storing error messages 

  useEffect(() => {
    const fetchBookingHistory = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          console.error('Token is missing');
          setError('Token is missing');
          setLoading(false);
          return;
        }

        // Make API request to fetch booking history
        const response = await axios.get(`${BOOKING_HISTORY}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });

        // Log the response data for debugging
        console.log(response.data);

        // Check if the response code is 200 and set booking history
        if (response.data.code === 200) {
          const bookings = response.data.data.bookings;  // Extract the bookings array

          // Ensure the bookings data is an array before setting the state
          if (Array.isArray(bookings)) {
            setBookingHistory(bookings);
          } else {
            setError('Booking history is not in expected format.');
          }
        } else {
          setError('No booking history found.');
        }
      } catch (err) {
        console.error('Error fetching booking history', err);
        setError('An error occurred while fetching the booking history.');
      } finally {
        setLoading(false); // Set loading to false after the fetch
      }
    };

    fetchBookingHistory(); // Call the function to fetch booking history on component mount
  }, []); // Empty dependency array means this will run once when the component mounts

  // Render loading, error, or booking history based on state
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={styles.container}>
        <div><h3 style={{color:"white"}}>BOOKING HISTORY</h3></div>
       <br></br>
       
      {bookingHistory.length === 0 ? (
        <p>No booking history available.</p>
      ) : (
        bookingHistory.map((booking, index) => (
            <div key={index} style={styles.cardContent}>
           
                <p style={styles.cardDescription}>
                <p style={{color:"red",fontWeight:"bold",fontSize:"18px"}}> {booking.userName}</p>
                <p> {booking.booking_id}</p> {/* Use booking_id instead of id */}
                <p>{booking.driverName}</p>
                <p> {booking.hospitalName}</p>
                <p>{moment(booking.created_at).format('DD MMM YYYY hh:mm A')}</p> {/* You can use 'created_at' as the booking date */}
             
                </p>
            </div>
        ))
    )}
    </div>
);
}



  const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#066951',
        backgroundImage: `url(${require('./assets/LyfGuardLogo.png')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '1800px 1800px',
        padding: 20,
    },
    cardContent: {
        borderRadius: 15,
        padding: '2%',
        background: 'linear-gradient(135deg, #EEEEEE,#EEEEEE, #F4A338)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        border: '1px solid white',
        marginBottom: 20,
        
    },
    cardTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: 'black',
    },
    cardDescription: {
        fontSize: 18,
        color: 'black',
    },
    Icon: {
        marginRight: 10,
        width: 18,
        height: 18,
        verticalAlign: 'middle',
    },
    Icons: {
        marginLeft: 10,
        width: 18,
        height: 18,
        verticalAlign: 'middle',
    },
    phoneLink: {
        color: '#007BFF',
        textDecoration: 'none',
    },
    Button: {
        padding: '10px 20px',
        fontSize: 16,
        color: '#fff',
        border: 'none',
        borderRadius: 15,
        cursor: 'pointer',
        marginRight: 10,
    },
    buttonRow: {
        display: 'flex',
        marginTop: 20,
    },
};

export default BookingHistory;
