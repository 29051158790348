import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GET_LISTING_TYPE } from './api';
import logo from "./assets/profileimage-removebg-preview.png";
import { Navbar, Nav, Container } from 'react-bootstrap';

const Header = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);

  // Fetching menu items from API
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No authorization token found');
        return;
      }

      try {
        const response = await axios.get(GET_LISTING_TYPE, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status !== 200) {
          console.error('HTTP error! Status:', response.status);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = response.data;
        if (data.code === 200) {
          setServices(data.data.types); // Set API response data as services
        } else {
          throw new Error(`API error! Code: ${data.code}`);
        }
      } catch (error) {
        setError(error.message);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handle clicking on a service item
  const handleCardClick = (id, slug) => {
    navigate(`/service-detail/${id}/${slug}`);
  };

  return (
    <header style={styles.container} className="main-header">
      <Navbar expand="md" style={styles.header}>
       
          {/* Logo Container */}
          <Navbar.Brand href="/" style={styles.logoText}>
            LYFGUARD
          </Navbar.Brand>

          {/* Menu Toggle (Hamburger Icon) for Mobile */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto" style={styles.menuItem}>
              {/* Static Link (First-Aid) */}

              <Nav.Link as={NavLink} to="/firstaid" style={styles.headerLink}>
                First-AID
              </Nav.Link>

              {/* Dynamic Service Links */}
              {services.map(service => (
                <Nav.Link
                  key={service.id}
                  onClick={() => handleCardClick(service.id, service.slug)}
                  style={styles.headerLink}
                >
                  {service.name}
                </Nav.Link>
              ))}

            </Nav>
            
              {/* Profile Logo */}
              <Nav.Link as={NavLink} to="/profile">
                <img
                  src={logo}
                  alt="Lyfguard Logo"
                  style={styles.logoImage}
                />
              </Nav.Link>
          </Navbar.Collapse>
     
      </Navbar>
    </header>
  );
};
                 

export default Header;


const styles = {
  logoText: {
    fontSize: "24px",  
    color: "white", 
    marginLeft:"2%" 
  },
  menuItem:{
marginRight:'15%'
  },

  container:{
    width:'100%',
  },
  
  header: {
    backgroundColor: 'rgba(6, 105, 81, 0.9)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  headerLink: {
    fontSize: '18px',
    color: 'white',
    textDecoration: 'none',
    margin: '0 10px',
  },
  headerLinklist: {
    fontSize: '18px',
    color: 'white',
    textDecoration: 'none',
    margin: '0 15px',
    cursor: 'pointer',
  },

  logoImage: {
    filter: 'invert(1) brightness(100%)',
    marginLeft: '10px',
    maxWidth: '40px',
    maxHeight: '40px',
    
    
  },
  
  
  
 
};
