// import React, { useEffect } from 'react';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import './Contact.css'; // Make sure the path is correct

// const Contact = () => {
//   useEffect(() => {
//     document.body.classList.add('contact-page');
//     return () => {
//       document.body.classList.remove('contact-page');
//     };
//   }, []);
//   return (
//     <Container className='contact-container'>
//       <div className='contact-content'>
//         <Row>
//           <Col md={6} className="mx-auto">
//             <div className='contact-form'>
//               <Form>
//                 <h2 className='mt-4'>Contact Us</h2>
//                 <p>Feel free to contact us any time. We will get back to you as soon as we can!</p>
                
//                 {/* Company Name */}
//                 <Form.Group controlId="formCompanyName" className="mb-3">
//                   <Form.Label>Company Name</Form.Label>
//                   <Form.Control type="text" placeholder="Enter your company name" className="contact-form-control" />
//                 </Form.Group>

//                 {/* Email */}
//                 <Form.Group controlId="formEmail" className="mb-3">
//                   <Form.Label>Email</Form.Label>
//                   <Form.Control type="email" placeholder="Enter your email" className="contact-form-control" />
//                 </Form.Group>

//                 {/* Phone Number */}
//                 <Form.Group controlId="formPhoneNumber" className="mb-3">
//                   <Form.Label>Phone Number</Form.Label>
//                   <Form.Control type="tel" placeholder="Enter your phone number" className="contact-form-control" />
//                 </Form.Group>

//                 {/* Address */}
//                 <Form.Group controlId="formAddress" className="mb-3">
//                   <Form.Label>Address</Form.Label>
//                   <Form.Control type="text" placeholder="Enter your address" className="contact-form-control" />
//                 </Form.Group>

//                 {/* Message */}
//                 <Form.Group controlId="formMessage" className="mb-3">
//                   <Form.Label>Message</Form.Label>
//                   <Form.Control as="textarea" rows={1} placeholder="Enter your message" className="contact-form-control" />
//                 </Form.Group>
                
               
//               </Form>
//             </div>
//           </Col>

//           <Col>
//             <div className='contact-info'>
//               <div className="contact-info-bg p-3 text-light">
//                 <h5 className='mt-4 ml-3'>Info</h5>
//                 <p className='ml-3'>
//                   <i className="fas fa-envelope mr-2 mt-4"></i> reach@lyfguard.in
//                 </p>
//                 <p className='ml-3'>
//                   <i className="fas fa-phone mr-2 mt-4"></i> 1800-889-1258
//                 </p>
//                 <p className='ml-3'>
//                   <i className="fas fa-map-marker-alt mr-2 mt-4"></i> Address
//                 </p>
//                 <p className='ml-3'>
//                   <i className="fas fa-clock mr-2 mt-4 mb-3"></i> 09:00-18:00
//                 </p>
//               </div>
//             </div>
//           </Col>
//         </Row>
//         <div className="button-container mt-2 mb-4">
//           <Button type="submit" className="contact-submit-btn">
//             Send Message
//           </Button>
//         </div>
//       </div>
//     </Container>
//   );
// };

// export default Contact;



import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoadScript } from "@react-google-maps/api";
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import Modal from 'react-modal';

const containerStyle = {
  width: '100%',
  height: '300px',
};

const DriverScreenPrivate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const { selectedButton } = state || {};
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "YOUR_GOOGLE_MAPS_API_KEY",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [pvtBookingDetails, setPvtBookingDetails] = useState(null);
  const [noAmbulanceModal, setNoAmbulanceModal] = useState(false);
  const [directions, setDirections] = useState(null);
  const [managerPhone, setManagerPhone] = useState(null);
  const [directionsUpdated, setDirectionsUpdated] = useState(false);
  const [updateInterval, setUpdateInterval] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);


  const isCancel = bookingDetails?.status === 10;
  const isCompleted = pvtBookingDetails?.status === 5;
  let noDataTimeout;

  const bookingId = state?.bookingId || localStorage.getItem('bookingId');

  useEffect(() => {
    if (!bookingId) {
      console.error('No booking ID found. Redirecting to home page.');
      navigate('/'); // Redirect to home or error page
    }
  }, [bookingId, navigate]);

 // Ensure this is defined in the correct scope

const fetchPrivateBookingDetails = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`https://api.lyfguard.in/lyfguard/public/api/private-ambulance-booking/${bookingId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      const responseData = response.data.data;
      console.log("Response Data:", responseData);

      // Always clear the timeout if data is received
      clearTimeout(noDataTimeout);

      // Check if the response data is "No Data"
      if (responseData === "No Data") {
        console.log("No Private Data received, setting timeout for popup.");

        // Set a timeout to show the popup if no data after 15 seconds
        noDataTimeout = setTimeout(() => {
          console.log("Timeout reached, showing popup.");
          showNoAmbulancePopup(); // Show the popup if there's no data after 15 seconds
        }, 15000); // 15000 milliseconds = 15 seconds
      } else {
        console.log("Data received, clearing timeout.");

        // Clear the timeout (if it was previously set) and update the booking details
        clearTimeout(noDataTimeout);
        setPvtBookingDetails(responseData.booking); // Assuming you want to set the booking details here
      }
    } else {
      console.error("Unexpected response code:", response.data.code);
      showNoAmbulancePopup();  // Show the popup if response code is not 200
    }

    console.log("Response Booking by private bookingId", response);
  } catch (error) {
    console.error('Error fetching booking details:', error);

    // Show the popup if an error occurs
    showNoAmbulancePopup();
  }
};

  useEffect(() => {
    const fetchDetails = () => {
      if (bookingId.includes('-pvb-')) {
        fetchPrivateBookingDetails();
     
      }
    };

    fetchDetails(); // Initial fetch

    const initialInterval = setInterval(() => {
      fetchDetails(); // Fetch every 15 seconds
    }, 15000);

    setUpdateInterval(initialInterval);
    return () => clearInterval(initialInterval);
  }, [bookingId]);

  useEffect(() => {
    const initialInterval = setInterval(() => {
     
      fetchPrivateBookingDetails();
    }, 15000); // Repeat every 15 seconds
    setUpdateInterval(initialInterval);
    return () => clearInterval(initialInterval);
  }, []);

  useEffect(() => {
    if (bookingDetails) {
      calculateRoute(bookingDetails.route_array, bookingDetails.user_latitude, bookingDetails.user_longitude);
    }
  }, [bookingDetails]);

  useEffect(() => {
    if (directionsUpdated) {
      clearInterval(updateInterval);
    }
  }, [directionsUpdated, updateInterval]);



//   const waypoints = JSON.parse(routeArray).map((point) => ({
//     location: new window.google.maps.LatLng(point.latitude, point.longitude),
//     stopover: true,
//   }));

//   const origin = waypoints.shift().location; // First point as origin
//   const destination = new window.google.maps.LatLng(userLat, userLng); // Destination

//   directionsService.route(
//     {
//       origin,
//       destination,
//       waypoints, // Intermediate stops
//       travelMode: window.google.maps.TravelMode.DRIVING,
//     },
//     (result, status) => {
//       if (status === window.google.maps.DirectionsStatus.OK) {
//         setDirections(result); // Set route to state
//         setDirectionsUpdated(true);
//       } else {
//         console.error(`Error fetching directions: ${status}`);
//       }
//     }
//   );
// };


  // const calculateRoute = (routeArray, userLat, userLng) => {
  //   if (!routeArray || isCanceled) return;

  //   const directionsService = new window.google.maps.DirectionsService();
  //   const waypoints = JSON.parse(routeArray).map((point) => ({
  //     location: new window.google.maps.LatLng(point.latitude, point.longitude),
  //     stopover: true,
  //   }));

  //   const origin = waypoints.shift().location;
  //   const destination = new window.google.maps.LatLng(userLat, userLng);

  //   directionsService.route(
  //     {
  //       origin,
  //       destination,
  //       waypoints,
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     },
  //     (result, status) => {
  //       if (status === window.google.maps.DirectionsStatus.OK) {
  //         setDirections(result);
  //         setDirectionsUpdated(true);
  //       } else {
  //         console.error(`Error fetching directions: ${status}`);
  //       }
  //     }
  //   );
  // };
  
  const calculateRoute = (userLat, userLng, branchLat, branchLng) => {
    if (!userLat || !userLng || !branchLat || !branchLng) return;
  
    const directionsService = new window.google.maps.DirectionsService();
  
    const origin = new window.google.maps.LatLng(userLat, userLng); // User location
    const destination = new window.google.maps.LatLng(branchLat, branchLng); // Branch location
  
    directionsService.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result); // Update state with directions
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };
  
  // Trigger route calculation after fetching booking details
  
  
  const handleCancelBooking = () => {
    const branchPhone =  pvtBookingDetails?.branchPhone;
  
    if (branchPhone) {
      window.open(`tel:${branchPhone}`); // Opens phone dialer with the branch phone
    } else {
      alert('Branch phone number not available');
    }
    clearInterval(updateInterval);
    setIsCanceled(true);
  };
  const showNoAmbulancePopup = () => {
    setNoAmbulanceModal(true);
};

  const parsedLat = bookingDetails ? parseFloat(bookingDetails.user_latitude) : 0;
  const parsedLng = bookingDetails ? parseFloat(bookingDetails.user_longitude) : 0;
  const isValidLatLng = !isNaN(parsedLat) && !isNaN(parsedLng);
  const status = bookingDetails?.status;

  return (
    <div style={styles.container}>
      <LoadScript googleMapsApiKey="AIzaSyCLg_Oahf9q1keNmJoqHc_Uk4f0fu3YmxU">
      <GoogleMap
  mapContainerStyle={containerStyle}
  center={{
    lat: parseFloat(bookingDetails?.user_latitude || 0),
    lng: parseFloat(bookingDetails?.user_longitude || 0),
  }}
  zoom={15}
>
  {directions && <DirectionsRenderer directions={directions} />}
</GoogleMap>

      </LoadScript>


      <div style={styles.centerSection}>
        <h1 style={styles.title}>{bookingId}</h1>
        <h5 style={styles.subtitle}>{selectedButton}</h5>
        <h6 style={styles.subtitle}>Driver En Route to Location</h6>
      </div>

    <Container fluid style={styles.gradient}>
      <Row className="justify-content-center">
        <Col xs={12} md={8} className="text-center">
          {isCancel ? (
            <>
              <Image src={require('./assets/delete.png')} alt="Booking Cancel" style={styles.statusImage} />
              <p style={styles.statusText}>Booking Cancel</p>
            </>
          ) : isCompleted ? (
            <>
              <Image src={require('./assets/accept.png')} alt="Booking Completed" style={styles.statusImage} />
              <p style={styles.statusText}>Booking Completed</p>
              <p style={styles.feedbackText}>
      <a href="/feedback" style={styles.feedbackLink}>Fill this feedback form</a>
    </p>
            </>
        
        ) : pvtBookingDetails || bookingDetails ? (
          <>
            <Image src={require('./assets/accept.png')} alt="Booking Successful" style={styles.statusImage} />
            <p style={styles.statusText}>Booking Successful</p>

            
          </>
        ) : (
          <>
            <Image src={require('./assets/waiting.png')} alt="Waiting for Confirmation" style={styles.waitingStyle} />
            <p style={styles.statusText}>Waiting for Confirmation</p>
          </>
        )}
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col xs={12} md={6} className="text-center">
          <p>Driver Name</p>
          <p style={styles.phoneLink}>
            {isCancel || isCompleted || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) 
              ? 'Not Available' 
              : (pvtBookingDetails?.driverName ||  'Not Available')}
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <p>Driver Phone</p>
          <a 
            href={`tel:${isCancel || isCompleted || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) ? '' : (pvtBookingDetails?.driverPhone ||  '')}`} 
            style={styles.phoneLink}
          >
            {isCancel || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) ? 'Not Available' : (pvtBookingDetails?.driverPhone ||  'Not Available')}
          </a>
        </Col>
      </Row>

      {/* Repeat similar structure for other details */}
      <Row className="mt-2 justify-content-center">
        <Col xs={12} md={6} className="text-center">
          <p>Branch Name</p>
          <p style={styles.phoneLink}>
            {isCancel || isCompleted || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) 
              ? 'Not Available' 
              : (pvtBookingDetails?.branchName ||  'Not Available')}
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <p>Branch Phone</p>
          <p style={styles.phoneLink}>
            {isCancel || isCompleted || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) 
              ? 'Not Available' 
              : (pvtBookingDetails?.bookingManagerPhone ||  'Not Available')}
          </p>
        </Col>
      </Row>

      <Row className="mt-2 justify-content-center">
        <Col xs={12} md={6} className="text-center">
          <p>Ambulance Number</p>
          <p style={styles.phoneLink}>
            {isCancel || isCompleted || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) 
              ? 'Not Available' 
              : (pvtBookingDetails?.vehicle_number ||  'Not Available')}
          </p>
        </Col>
        <Col xs={12} md={6} className="text-center">
          <p>Ambulance Type</p>
          <p style={styles.phoneLink}>
            {isCancel || isCompleted || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) 
              ? 'Not Available' 
              : (pvtBookingDetails?.ambulanceTypeName ||  'Not Available')}
          </p>
        </Col>
      </Row>

      <Row className="mt-0">                                                 
        <Col xs={12} md={6} className="text-center">
          <p>Driver OTP</p>
          <p style={styles.phoneLink}>
            {isCancel || isCompleted || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) 
              ? 'Not Available' 
              : (pvtBookingDetails?.
                driver_otp || 'Not Available')}
          </p>
        </Col>
      </Row>
      {/* Continue for other fields like Ambulance Number, Type, and Driver OTP */}

      <Row className="mt-4">         
        <Col className="text-center"> 
          {isCancel || (pvtBookingDetails?.driver_status === 4 || pvtBookingDetails?.driver_status === 5) ? (
            <>
              <p style={styles.bottomtitle}>Your booking has been canceled Successfully !!</p>
              <Button variant="primary" style={styles.backtohomebutton} onClick={() => navigate('/private')}>
                BACK TO BOOKING
              </Button>
            </>
          ) : bookingDetails ? (
            <>
              <p style={styles.bottomtitle}>Booked by mistake or change of plans?</p>
              <Button 
          variant="danger" 
          style={styles.cancelbutton} 
          onClick={handleCancelBooking}
          disabled={isCancel || isCompleted}
        >
          CANCEL BOOKING
        </Button>
            </>
          ) : (
            <>
              <Button variant="danger" style={styles.cancelbutton} disabled>
                CANCEL BOOKING
              </Button>
            </>
          )}
        </Col>
      </Row>

       <Modal
                      isOpen={noAmbulanceModal}
                      onRequestClose={() => setNoAmbulanceModal(false)}
                      style={{
                          content: {
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '40%',
                              textAlign: 'center',
                              padding: '20px',
                          },
                      }}
                      ariaHideApp={false}
                  >
                      <h2>No Ambulance Found</h2>
                      <p>Sorry, we couldn't find an available ambulance at the moment.</p>
                      <Button className="btn-danger mx-2" onClick={() => navigate('/private')}>
                          Back to Booking
                      </Button>
                      <Button className="btn-secondary mx-2" onClick={() => navigate('/nearbyhospital')}>
                          Nearby Ambulance
                      </Button>

                      
                  </Modal>
    </Container>

    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#D3FFD8',
    minHeight: '100vh'
  },
  waitingStyle: {
    width: '100px',
    height: '100px',
  },
  
  centerSection: {
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'red',
    margin: '3%'
  },
  subtitle: {
    color: 'green',
    fontSize: '20px',
    margin: '3%'
  },
  gradient: {
    background: 'linear-gradient(to right, #FFFFFF, #FFFFFF)',
    borderRadius: 15,
    width: '80%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginBottom: '3%'
  },
  detailContainer: {
    width: '100%',
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px'
  },
  detailItem: {
    flexDirection: 'column',
    marginRight: '100px',
    marginLeft: '100px'
  },
  
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    flex: 1,
    borderRadius: 5,
    padding: 10,
    margin: 5,
    textAlign: 'center',
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  secondaryButton: {
    backgroundColor: 'red',
  },
  noDataContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  noDataText: {
    color: 'red',
  },
  phoneLink: {
    color: 'red',
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomtitle: {
    fontSize: '18px',
    textAlign: 'center',
  },
  cancelbutton: {
    borderRadius: 5,
    padding: 10,
    textAlign: 'center',
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '75%',
    marginBottom: '2%'
  },
  backtohomebutton: {
    borderRadius: 5,
    padding: 10,
    textAlign: 'center',
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '75%',
    marginBottom: '2%'
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  statusImage: {
    width: '40px', // Adjust size as needed
    height: '40px', // Adjust size as needed
    marginRight: '10px', // Space between image and text
  },
  statusText: {
    marginTop: '20px',
    fontSize: '25px',
    fontWeight: 'bold',
  },
};

export default DriverScreenPrivate;
