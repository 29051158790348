import React, { useState, useRef, useEffect} from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import myImage from "./assets/Lyfguard-red-logo.png";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";
import "./joinus.css"; // You can still keep this if it contains component-specific styles.
import FooterScreen from './footer';
import FloatingButton from './FloatingButton';
import Navigation from './navbar';

const Joinus = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    role: "",
    remarks: "",
  });

  const [errors, setErrors] = useState({ phoneNumber: "" });
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [validFields, setValidFields] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    role: false,
    remarks: false,
  });
  const sliderRef = useRef(null);
    const overViewRef = useRef(null);
    const teamRef = useRef(null);
    const contactRef = useRef(null);
    useEffect(() => {
      if (contactRef.current) {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);
    

  const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
          name: formData.name,
          phoneNumber: formData.phoneNumber,
          email: formData.email,
          role: formData.role,
          remarks: formData.remarks,
        };
    
        try {
          const response = await fetch('http://localhost:3000/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
    
          if (response.ok) {
            const responseData = await response.json();
            console.log('Form submitted successfully:', responseData);
            setSubmissionStatus('success');
            setShowPopup(true); // Show the popup on successful form submission
            setTimeout(() => {
              setShowPopup(false);
              navigate('/home'); // Navigate to home after popup closes
            }, 5000); // Adjust time as needed
          } else {
            console.error('Form submission failed:', response.statusText);
            setSubmissionStatus('error');
            setShowPopup(true);
            setTimeout(() => {
              setShowPopup(false);
            }, 5000);
          }
        } catch (error) {
          console.error('Fetch error:', error.message);
          setSubmissionStatus('error');
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
          }, 5000);
        }
      };
    

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    let isValid = false;
    if (name === "phoneNumber") {
      isValid = /^\d{10}$/.test(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: isValid
          ? ""
          : "Phone number can only contain digits and must be exactly 10 digits long.",
      }));
    } else if (name === "email") {
      isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    } else if (name === "role") {
      isValid = value !== "";
    } else if (name === "remarks") {
      isValid = value.length > 0;
    } else if (name === "name") {
      isValid = value.trim().length > 0;
    }

    setValidFields((prevValidFields) => ({
      ...prevValidFields,
      [name]: isValid,
    }));
  };

  const handleClosePopup = () => setShowPopup(false);

  return (
    <div style={styles.container} ref={contactRef}>

      <FloatingButton />

      <Navigation
      sliderRef={sliderRef}
      overViewRef={overViewRef}
      teamRef={contactRef}
      contactRef={contactRef}
      />
      <section style={styles.section1}>
        <img
          src={require("./assets/LyfGurad-white-logo.png")} // Replace with your image path
          alt="Top Left"
          style={styles.topLeftImage}
        />
        <h1 style={styles.secheading}>Join Us</h1>
      </section>

    <Container className="custom-joinus-container mt-5 ">
      <Row className="no-space justify-content-center">
        <Col md={6}>
          <Card className="custom-card w-75 mx-auto">
            <Card.Body>
              <h4 className="mb-1 text-center  ">Contact Form</h4>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName" className="position-relative">
                  <Form.Label>
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="text"
                      placeholder="Enter your name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="formPhoneNumber"
                  className="position-relative"
                >
                  <Form.Label>
                    Phone Number <span className="text-danger ms-1">*</span>
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="tel"
                      placeholder="Enter your phone number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                      isInvalid={!!errors.phoneNumber}
                      pattern="[0-9]{10}"
                      maxLength="10"
                      title="Please enter exactly 10 digits."
                      className="form-control"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>

                <Form.Group controlId="formEmail" className="position-relative">
                  <Form.Label>
                    Email <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                </Form.Group>

                <Form.Group controlId="formRole" className="position-relative">
                  <Form.Label>
                    Role <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      as="select"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      required
                      className="form-control"
                    >
                      <option value="">Select Role</option>
                      <option value="Private ambulance agent">
                        Private ambulance agent
                      </option>
                      <option value="Hospital Emergency">
                        Hospital Emergency
                      </option>
                      <option value="Mortuary">Mortuary</option>
                    </Form.Control>
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="formRemark"
                  className="position-relative"
                >
                  <Form.Label>Remark</Form.Label>
                  <div className="input-group">
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Enter any remarks"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </Form.Group>

                <div className="center-button mt-2 mx-auto">
                  <Button
                    variant="danger"
                    type="submit"
                    className="submit-button"
                    style={{ width: "auto", maxWidth: "150px" }} // Adjust the maxWidth as needed
                  >
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <img
            src={myImage}
            alt="LyfGuard Logo"
            style={{ width: "100%", height: "93%" }}
          />
        </Col>
       
      </Row>
      
      <Popup show={showPopup} handleClose={handleClosePopup} />
      
    </Container>
    <FooterScreen />
    </div>
    
  );
};

export default Joinus;

const styles = {
  section1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    padding: "20px",
    backgroundColor: "#066951",
    backgroundImage: `url(${require("./assets/LyfGuardLogo.png")})`,
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  secheading: {
    color: "white",
    fontSize: "35px",
    marginTop: "5%",
  },
  topLeftImage: {
    position: "absolute",
    top: "15%",
    width: "100px",
    height: "auto",
    zIndex: 1,
  },
  row: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "10px",
    color: "white",
  },
  heading: {
    marginTop: "8%",
    textAlign: "center",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    marginTop: 20,
    cursor: "pointer",
  },
  cardsction2: {
    width: "100%",
    backgroundColor: "white",
    padding: 10,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.2s ease",
    marginTop: "4%",
  },
  teamName: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  teamDescription: {
    fontSize: "14px",
  },
  subteamDescription: {
    fontSize: "12px",
  },
  avatar: {
    height: "80px",
    width: "68px",
    marginBottom: "5%",
  },
  section2Text1: {
    color: "black",
    textAlign: "center",
    marginTop: "2%",
    fontSize: "18px",
    fontWeight: "700",
  },
  section2Text3: {
    color: "#4D4F4D",
    textAlign: "center",
    fontSize: "14px",
  },
};